<ion-content color="fondo">
  <ion-card class="ion-text-center" color="fondo">
    
    <ion-text color="text-primary">
      Completa tu perfil
    </ion-text>
    <br><br>
    <ion-text color="text-color">
      En PAGï la seguridad de tus datos es nuestra prioridad, por favor completa tu perfil para realizar esta transacción. Este paso sólo lo harás la primera vez que utilices nuestra APP.
    </ion-text>
    <br><br>
    <ion-text color="text-color">
      Esta información es confidencial y no se mostrará en tu perfil.
    </ion-text>
    <br>

    <form [formGroup]="completeInfoForm" novalidate class="ion-text-center" >
      <ion-item color="fondo">
        <ion-label position="stacked" color="text-primary">Documento de identidad*</ion-label>
        <ion-input formControlName="dui" mask="00000000-0" ></ion-input>
        <ion-text color="text-tertiary" *ngIf="completeInfoForm.get('dui').hasError('required')" >Campo Requerido</ion-text>
        <ion-text color="text-tertiary" *ngIf="completeInfoForm.get('dui').hasError('minlength')" >Dui no valido</ion-text>
      </ion-item>

      <ion-item color="fondo">
        <ion-label position="stacked" color="text-primary">Numero Telefonico*</ion-label>
        <ion-input formControlName="phone" mask="0000-0000"></ion-input>
        <ion-text color="text-tertiary" *ngIf="completeInfoForm.get('phone').hasError('required')" >Campo Requerido</ion-text>
        <ion-text color="text-tertiary" *ngIf="completeInfoForm.get('phone').hasError('minlength')" >Numero Telefonico no valido</ion-text>
      </ion-item>
      <br>

      <ion-grid>
        <ion-row>
          <ion-col size="8" offset="2">
            <ion-button color="text-primary" expand="block" shape="round" size="small" (click)="saveInfo()" [disabled]="completeInfoForm.invalid">
              <ion-label color="fondo">
                Continuar
              </ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      
    </form>


  
  </ion-card>
</ion-content>
