<ion-header>
    <ion-toolbar color='fondo'>
        <ion-buttons slot="start" (click)="dismiss()">
            <ion-icon slot="icon-only" name="arrow-back-outline" color="text-primary"></ion-icon>
        </ion-buttons>
        <ion-title color="text-primary">
            Comentarios
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content color="fondo">

    <div class="post_area">


        <ion-item lines="none" class="ion-no-margin">
            <ion-avatar slot="start" routerLink="profile/{{post?.from_user?.$key}}">
                <img src="assets/imageholder.svg" *ngIf="!post?.from_user?.profilePicture">
                <img src="{{post?.from_user?.profilePicture.downloadURL}}" *ngIf="post?.from_user?.profilePicture">
            </ion-avatar>
            <ion-label class="usernames" color="text-secondary">
                <ion-text color="text-primary" *ngIf="auth.token !== post?.from_user?.$key"
                    routerLink="profile/{{post?.from_user?.$key}}">
                    {{post?.from_user?.username}}
                </ion-text>

                <ion-text color="text-secondary" >
                    envió un pagi de
                </ion-text>

                <ion-text color="text-primary">
                    ${{post.amount | currency:' ï'}}
                </ion-text>

                <ion-text color="medium">
                    a
                </ion-text>

                <ion-text color="text-primary" *ngIf="auth.token !== post?.to_user?.$key"
                    routerLink="profile/{{post?.to_user?.$key}}">
                    @{{post?.to_user?.username}}
                </ion-text>

            </ion-label>
        </ion-item>

        <ion-item lines="none" *ngIf="post?.caption" class="ion-no-margin">
            <ion-label class="ion-text-wrap">
                <ion-text color="text-primary">
                    <p color="text-secondary">
                        {{post?.caption}}
                    </p>
                </ion-text>
            </ion-label>
        </ion-item>



        <ion-item lines="none" class="ion-no-padding ion-no-margin">
            <ion-button class="button-liked" fill="clear" color="{{post.liked}}" size="small" strong="true"
                (click)="like(post)">
                <ion-icon name="heart-outline" slot="icon-only"></ion-icon>
                {{post.likes}}
            </ion-button>

            <ion-button fill="clear" color="text-secondary" size="small" strong="true" (click)="goToComments(post)">
                <ion-icon name="chatbubbles-outline"></ion-icon>
                {{post.comments}}
            </ion-button>
        </ion-item>
    </div>

    <div class="line ion-margin-bottom"></div>

    <ion-item lines="none" *ngFor="let comment of comments" class="ion-no-margin">
        <ion-avatar slot="start">
            <img src="assets/imageholder.svg" *ngIf="!comment?.user?.profilePicture">
            <img src="{{comment?.user?.profilePicture.downloadURL}}" *ngIf="comment?.user?.profilePicture">
        </ion-avatar>
        <ion-label class="usernames ion-text-wrap" color="text-secondary">
            <ion-text class="small bold" color="text-primary" strong>
                @{{comment.user.username}}
            </ion-text>
            <ion-text class="small" color="text-secondary">
                {{comment.comment}}
            </ion-text>
            <p class="small" color="text-secondary">{{comment.moment}}</p>
        </ion-label>

    </ion-item>

</ion-content>

<ion-footer class="ion-padding ion-text-left" color="fondo">

    <form [formGroup]="commentForm" novalidate color="fondo">
        <ion-item lines="none" color="fondo">
            <ion-avatar>
                <img src="assets/imageholder.svg" *ngIf="!user?.profilePicture">
                <img src="{{user?.profilePicture.downloadURL}}" *ngIf="user?.profilePicture">
            </ion-avatar>
            <ion-label class="ion-margin-start ion-no-padding">
                <mbsc-textarea placeholder="Add comment..." class="custom" formControlName="comment"></mbsc-textarea>
            </ion-label>
            <ion-button fill="clear" slot="end" [disabled]="commentForm.invalid" (click)="addComment()" color="text-primary"
                class="ion-no-margin ion-no-padding">
                <strong>
                    Send
                </strong>
            </ion-button>
        </ion-item>
    </form>

</ion-footer>