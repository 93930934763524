<ion-header>
    <ion-toolbar color='fondo'>
        <ion-button color="warning" slot="start" size="small" (click)="dismiss()" fill="outline">
            <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
                Editar perfil
        </ion-button>
       
    </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center" color="fondo">


    
    <ion-avatar class="ion-margin-top" (click)="selectImageProfile()">
        <img src="assets/imageholder.svg" *ngIf="!account?.profilePicture">
        <img src="{{account?.profilePicture.downloadURL}}" *ngIf="account?.profilePicture">
    </ion-avatar>
    <br>
    <ion-label class="username" color="text-primary" (click)="selectImageProfile()">
        {{account?.username}}
    </ion-label>
    <br><br>
    <form [formGroup]="accountForm" novalidate class="ion-text-center" >
        <ion-list lines="none">
            <ion-item color="fondo" >
                <ion-label position="stacked" color="text-primary">Nombre</ion-label>
                <ion-input autocapitalize="true" formControlName="fullname"></ion-input>
                <ion-text color="text-tertiary"  *ngIf="accountForm.get('fullname').hasError('pattern')" >Solo carácteres alfabéticos.</ion-text>
            </ion-item>

            <ion-item color="fondo">
                <ion-label position="stacked" color="text-primary">Estado</ion-label>
                <ion-input autocapitalize="true" formControlName="estado"></ion-input>
            </ion-item>

            <ion-item color="fondo">
                <ion-label position="stacked" color="text-primary">Numero Telefonico*</ion-label>
                <ion-input formControlName="phone" mask="0000-0000"></ion-input>
                <ion-text color="text-tertiary" *ngIf="accountForm.get('phone').hasError('required')" >Campo Requerido</ion-text>
                <ion-text color="text-tertiary" *ngIf="accountForm.get('phone').hasError('minlength')" >Numero Telefonico no valido</ion-text>
            </ion-item>

            <ion-item color="fondo">
                <ion-label position="stacked" color="text-primary">Documento de identidad*</ion-label>
                <ion-input formControlName="dui" mask="00000000-0" ></ion-input>
                <ion-text color="text-tertiary" *ngIf="accountForm.get('dui').hasError('required')" >Campo Requerido</ion-text>
                <ion-text color="text-tertiary" *ngIf="accountForm.get('dui').hasError('minlength')" >Dui no valido</ion-text>
            </ion-item>

            <ion-item color="fondo">
                <ion-label position="stacked" color="text-primary">Fecha de nacimiento*</ion-label>
                <ion-datetime displayFormat="DD MMM YYYY" placeholder="Select Date" formControlName="birthdate"></ion-datetime>
            </ion-item>
            
            <ion-item color="fondo">
                <ion-label position="stacked" color="text-primary">Genero*</ion-label>
                <ion-select placeholder="Select One" interface="popover" formControlName="gender">
                    <ion-select-option value="female">Mujer</ion-select-option>
                    <ion-select-option value="male">Hombre</ion-select-option>
                </ion-select>
            </ion-item>

        </ion-list>

        <br>
        <p color="text-color" style="text-align: center; font-size: 13px ; margin: 15px;">* Esta información es confidencial y no se mostrará en tu perfil personal</p>

        <ion-grid>
            <ion-row>
                <ion-col size="8" offset="2">
                    <ion-button class="saveButton" expand="block" color="primary" fill="solid" [disabled]="accountForm.invalid" (click)="done()"> Guardar </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>