<ion-content color="fondo" class="ion-padding">
    <ion-buttons>
        <ion-button color="text-primary" shape="round" expand="block" class="" (click)="close_btn()"><ion-label color="text-primary">Notificaciones</ion-label>
            <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
    </ion-buttons>
    <br>
    <ion-grid >
        <ion-row  class="ion-text-center">
            <ion-col size="4" offset="4">
                <ion-avatar>
                    <img src="assets/imageholder.svg" *ngIf="!user?.profilePicture">
                    <img src="{{user?.profilePicture.downloadURL}}" *ngIf="user?.profilePicture">
                </ion-avatar>
            </ion-col>
        </ion-row>
    </ion-grid>

    <ion-row class="margin-bottom centerItems">
        <ion-col size="12" class="number_pad ion-text-center ion-no-padding">
            <ion-row>
                <ion-col size="12">
                    <h4><ion-label color="text-primary">@{{user?.username}}</ion-label></h4>
                    <ion-label *ngIf="request?.from_user?.estado" color="text-color">{{request?.from_user?.estado}}<br></ion-label>
                    <ion-label *ngIf="!request?.from_user?.estado" color="text-color">Sin estado</ion-label><br>
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-row>

    
    
    <ion-row class="margin-bottom centerItems">
        <ion-col size="12" class="number_pad ion-text-center ion-no-padding">
            <ion-row>
                <ion-col size="12">
                    <ion-label color="text-primary" class="ion-text-right" style="font-size: 3em">
                        ï {{request?.amount | number}}
                    </ion-label>
                    <br><br>
                    <ion-label color="text-primary" style="font-size: 20px;"> Solicitud de PAGï</ion-label>
                    <br><br>
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-row>

  <!--
        <ion-label color="text-primary"  *ngIf="visibleButton != false">Solicitud de pagï a:</ion-label>
    <h4 *ngIf="visibleButton != false"><ion-label color="text-primary">{{userToRequest?.username}}</ion-label></h4>
    
    <ion-label color="text-primary" *ngIf="visibleButton == false">Solicitó un pagï de:</ion-label><br>

    <ion-label color="text-primary">
        {{request.comment}}
    </ion-label>
  -->

        

    <form [formGroup]="paymentForm" novalidate>

        <ion-text class="justify-content-left" color="text-primary">Descripcion</ion-text>  
        <ion-input style="border-bottom: #FFDB33; border-bottom-style: solid; " name="caption" placeholder="Descripcion..." color="text-secondary" formControlName="comment">
        </ion-input>
        <br>

        <ion-item lines="none" style="border-bottom: #FFDB33; border-bottom-style: solid; ">
            <ion-label color="text-primary">Privacidad</ion-label><br>
            
            <ion-select placeholder="Select One" interface="popover" formControlName="privacy">

                <ion-select-option color="text-primary" value="public">
                    Público
                </ion-select-option>

                <ion-select-option color="text-primary" value="private">
                    Privado
                </ion-select-option>
            </ion-select>
        </ion-item>
        <br>

        <ion-text class="justify-content-left" color="text-primary">Método de pago</ion-text><br>
                  <ion-button color="text-primary" color="text-primary" fill="clear" expand="block" size="medium"
                      (click)="paymentMethods($event)">
                      <ion-label *ngIf="card">
                          <ion-icon slot="start" src="./assets/icons/{{card.cardType}}.svg"></ion-icon>
                          • • • • {{card.cardMask}}
                      </ion-label>
                      <ion-label *ngIf="!card">
                          <ion-text color="text-secondary">
                              Seleccione una forma de pago
                          </ion-text>
                      </ion-label>
                      <ion-icon slot="end" name="caret-down-outline"></ion-icon>
                  </ion-button>
                  <ion-text color="danger" *ngIf="cardError">
                      Debe seleccionar un metodo de pago
                  </ion-text>
                  <hr style="border-bottom: #FFDB33; border-bottom-style: solid; "> 
                  <br><br>

                  <ion-grid>
                    <ion-row>
                        <ion-col size="4">
                            <ion-button color="primary" shape="round" size="small" (click)="sendPayment()" [disabled]="paymentForm.invalid" *ngIf="visibleButton != true">
                                <ion-icon slot="start" name="send-sharp"></ion-icon>
                                Enviar
                            </ion-button>
                        </ion-col>
                        <ion-col size="4" *ngIf= "this.request.account_type == 'commerce'">
                            <ion-button color="secondary" shape="round" size="small" (click)="splitAccount()" [disabled]="paymentForm.invalid" *ngIf="visibleButton != true">
                                <ion-icon slot="start" name="git-network-outline"></ion-icon>
                                Split
                            </ion-button>
                        </ion-col>
                        <ion-col size="4">
                            <ion-button color="secondary" shape="round" size="small" (click)="cancelRequest()" *ngIf="visibleButton != true">
                                <ion-icon slot="start" name="remove-circle-outline"></ion-icon>
                                Rechazar 
                            </ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>

                  <ion-button color="primary" shape="round" size="small" (click)="cancelPayment()" *ngIf="visibleButton == true">
                      <ion-icon slot="start" name="send-sharp"></ion-icon>
                      Cancelar solicitud
                  </ion-button>

    </form>


</ion-content>