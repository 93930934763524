import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/tabs/tabs/tabs.module').then(m => m.TabsPageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'signup',
        loadChildren: () => import('./pages/auth/signup/signup.module').then(m => m.SignupPageModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'payment_methods',
        loadChildren: () => import('./pages/payment-methods/payment-methods.module').then(m => m.PaymentMethodsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'activity',
        loadChildren: () => import('./pages/activity/activity.module').then(m => m.ActivityPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'chat/:user_key',
        loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'friend-requests',
        loadChildren: () => import('./pages/friend-requests/friend-requests.module').then(m => m.FriendRequestsPageModule)
    },
    {
        path: 'friends-list',
        loadChildren: () => import('./pages/friends-list/friends-list.module').then(m => m.FriendsListPageModule)
    },
    {
        path: 'LoginInitPage',
        loadChildren: () => import('./pages/login-init/login-init.module').then(m => m.LoginInitPageModule)
    },
    {
        path: 'search',
        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
        // loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
    },
    {
        path: 'tabs/profile/:user_key',
        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
    },
    {
        path: 'activity-info',
        loadChildren: () => import('./pages/activity-info/activity-info.module').then(m => m.ActivityInfoPageModule)
    },
    {
        path: 'searh-shops',
        loadChildren: () => import('./pages/searh-shops/searh-shops.module').then(m => m.SearhShopsPageModule)
    },
    {
        path: 'code-qr',
        loadChildren: () => import('./pages/code-qr/code-qr.module').then(m => m.CodeQrPageModule)
    },
    {
        path: 'searh-shops',
        loadChildren: () => import('./pages/searh-shops/searh-shops.module').then( m => m.SearhShopsPageModule)
      },
      {
        path: 'commerce-details/:id',
        loadChildren: () => import('./pages/commerce-details/commerce-details.module').then( m => m.CommerceDetailsPageModule)
      },
      {
        path: 'menu/:id/:name',
        loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
      },


];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
