<ion-header>
    <ion-toolbar color='fondo'>
        <ion-title color="primary">
            New Chat
        </ion-title>
        <ion-buttons slot="end" (click)="dismiss()">
            <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content color="fondo">
    <ion-list>
        <ion-item color="fondo" lines="full" *ngFor="let friend of friends" (click)="selectFriend(friend)" class="margin">
            <ion-avatar slot="start">
                <img src="{{friend.profilePicture.downloadURL}}" *ngIf="friend.profilePicture">
                <img src="assets/imageholder.svg" *ngIf="!friend.profilePicture">
            </ion-avatar>
            <ion-label color="primary">
                <h3>{{friend.fullname}}</h3>
                <p>{{friend.username}}</p>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>