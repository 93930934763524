<ion-header>
    <ion-toolbar color="fondo">

        <ion-buttons slot="start">
            <ion-button fill="clear" (click)="closeModal()" color="text-primary" text="ATRAS">
                <ion-icon name="chevron-back-outline" color="text-primary" expand="icon-only"></ion-icon>ATRAS
            </ion-button>

        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content color="fondo">

    <div class="gridLogo">
        <img class="imgPagi" src="./assets/yellow-logo.svg">
    </div>
    <ion-grid style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <ion-row>
            <ion-col size="12" color="text-primary">
                <h1>Recupera tu contraseña</h1>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col size="10" offset="1">
                <p style="color: #ffffff;">Ingresa el correo electrónico que utilizaste para
                    registrarte. Te enviaremos un correo para reestablecer la contraseña de tu cuenta.
                </p>
            </ion-col>
        </ion-row>
    </ion-grid>

    <ion-grid>
        <ion-row>
            <ion-col style="margin: 10px;">
                <ion-item class="signInput" lines="none">
                    <ion-label position="stacked" class="inputLabel" autofocus color="text-primary">
                        Correo Electrónico
                    </ion-label>
                    <ion-input color="text-secondary" [(ngModel)]='recover'></ion-input>
                </ion-item>

                <ion-button color="text-primary" [disabled]="recover === '' " (click)="recoverAccount()" class="sendButt">
                    Enviar
                </ion-button>
                <br>
            </ion-col>
        </ion-row>
        <ion-row style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <ion-text color="text-primary" (click)="closeModal()">volver al inicio</ion-text>
        </ion-row>
    </ion-grid>

</ion-content>