import { Component } from '@angular/core';

import { Platform, AlertController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth/auth.service';
import { ApiService } from './services/api/api.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { RequestPopupComponent } from './components/request-popup/request-popup.component';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { ToastController } from '@ionic/angular';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public auth: AuthService,
        private oneSignal: OneSignal,
        public toastController: ToastController,
        public modalCtrl: ModalController,
        public api: ApiService,
        public alertCtrl: AlertController,
        private angularAuth: AngularFireAuth,
    ) {
        this.initializeApp();
        this.setupPush();

    }

    initializeApp() {
        this.platform.ready().then(() => {

                
            this.statusBar.styleDefault();
            this.initFunctions();
            this.splashScreen.hide();
        });
    }

    initFunctions() {

        let self = this;
        this.api.getCollectionRef(`requests`).ref
            .where('shown', '==', false)
            .where('to_account', '==', this.auth.token)
            .onSnapshot(snapshots => {

                snapshots.docChanges().forEach(change => {
                    if (change.type === "added") {
                        let request = change.doc.data();
                        request.$key = change.doc.id;
                        self.showRequestPopUp(request)
                    }
                });

            })
    }

    async showRequestPopUp(request) {
        const modal = await this.modalCtrl.create({
            component: RequestPopupComponent,
            componentProps: {
                request: request
            },
            animated: true,
            cssClass: 'requestModal'
        });

        return await modal.present();
    };

    
    setupPush() {

        this.oneSignal.startInit('edf12374-085e-4a7c-a310-354089e9fff0', '369705893702');

        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);

        this.oneSignal.handleNotificationReceived().subscribe(data => {
            let msg = data.payload.body;
            let title = data.payload.title;
            let additionalData = data.payload.additionalData;
            this.toastController.create({
                header: title,
                message: msg,
                duration: 2000,
                position: 'top'
            }).then(toaster => {
                toaster.present();
            })
        });

        this.oneSignal.handleNotificationOpened().subscribe(data => {
            let title = data.notification.payload.title;
            let msg = data.notification.payload.body;
            this.toastController.create({
                header: title,
                message: msg,
                duration: 2000,
                position: 'top'
            }).then(toaster => {
                toaster.present();
            })

        });

        if (this.auth.token) {
            this.oneSignal.getIds().then(identity => {
                this.api.getRef(`accounts/${this.auth.token}/push_keys/`).ref
                    .where('push_key', '==', identity.userId)
                    .onSnapshot(data => {
                        if (!data.size) {
                            this.api.addDocument(`accounts/${this.auth.token}/push_keys/`, { push_key: identity.userId }).then(data => {
                                console.log('ok');
                            })
                        }
                    })
            });
        }

        this.angularAuth.authState.subscribe((auth) => {
            if (auth !== null) {

                this.oneSignal.getIds().then(identity => {
                    this.api.getRef(`accounts/${this.auth.token}/push_keys/`).ref
                        .where('push_key', '==', identity.userId)
                        .onSnapshot(data => {
                            if (!data.size) {
                                this.api.addDocument(`accounts/${this.auth.token}/push_keys/`, { push_key: identity.userId }).then(data => {
                                    console.log('ok');
                                })
                            }
                        })

                });
            }
        });
        this.oneSignal.endInit();
    }
}
