<ion-content color="dark" class="border-color ion-padding">
  <ion-list color="dark" lines="none">

    <ion-item color="dark">
      <ion-label color="text-primary">Categorías</ion-label>
      <ion-icon name="chevron-forward-outline" slot="end" color="text-primary">
      </ion-icon>
    </ion-item>

    <ion-item color="dark">
      <ion-label color="text-primary">mis reservaciones</ion-label>
      <ion-icon name="chevron-forward-outline" slot="end" color="text-primary">
      </ion-icon>
    </ion-item>
    
  </ion-list>
</ion-content>