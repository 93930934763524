import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MbscModule } from '@mobiscroll/angular';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule, FirebaseApp } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { firebaseConfig } from './../environments/environment.prod';
import { AuthGuard } from './guards/auth/auth.guard';

import { SettingsMenuComponent } from './components/settings-menu/settings-menu.component';
import { AddFriendsSplitComponent } from './components/add-friends-split/add-friends-split.component';
import { SplitComponent } from './components/split/split.component';
import { AddChatboxComponent } from './components/add-chatbox/add-chatbox.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { CommentsComponent } from './components/comments/comments.component';
import { SendPaggiComponent } from './components/paggis/send-paggi/send-paggi.component';
import { AddPaymentMethodComponent } from './components/add-payment-method/add-payment-method.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { PaymentConfirmationComponent } from './components/payment-confirmation/payment-confirmation.component';
import { RequestPaggiComponent } from './components/paggis/request-paggi/request-paggi.component';
import { RequestPopupComponent } from './components/request-popup/request-popup.component';
import { ChargePaggiComponent } from './components/paggis/charge-paggi/charge-paggi.component';
import { CompleteInfoComponent } from './components/complete-info/complete-info.component';

import { OneSignal } from '@ionic-native/onesignal/ngx';

import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Badge } from '@ionic-native/badge/ngx';

import { registerLocaleData } from '@angular/common';
import locales from '@angular/common/locales/es-SV';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
// import { NgxMaskIonicModule } from 'ngx-mask-ionic'
import { ModalBirthDateComponent } from './components/modal-birth-date/modal-birth-date.component';
import { MenuComerciosComponent } from './components/menu-comercios/menu-comercios.component';
import { TermsComponent } from './components/terms/terms.component';


registerLocaleData(locales, 'es');

@NgModule({
    declarations: [
        AppComponent,
        RequestPopupComponent,
        RequestPaggiComponent,
        PaymentConfirmationComponent,
        PaymentMethodsComponent,
        AddPaymentMethodComponent,
        SendPaggiComponent,
        CommentsComponent,
        EditProfileComponent,
        AddChatboxComponent,
        SplitComponent,
        AddFriendsSplitComponent,
        SettingsMenuComponent,
        ForgotPasswordComponent,
        ModalBirthDateComponent,
        MenuComerciosComponent,
        TermsComponent,
        ChargePaggiComponent,
        CompleteInfoComponent
    ],
    entryComponents: [
        AppComponent,
        RequestPopupComponent,
        RequestPaggiComponent,
        PaymentConfirmationComponent,
        PaymentMethodsComponent,
        AddPaymentMethodComponent,
        SendPaggiComponent,
        CommentsComponent,
        EditProfileComponent,
        AddChatboxComponent,
        SplitComponent,
        AddFriendsSplitComponent,
        SettingsMenuComponent,
        ForgotPasswordComponent,
        ModalBirthDateComponent,
        MenuComerciosComponent,
        TermsComponent,
        ChargePaggiComponent,
        CompleteInfoComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MbscModule,
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios',
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        HttpClientModule,
        // NgxMaskIonicModule.forRoot(),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        AuthGuard,
        Keyboard,
        Camera,
        OneSignal,
        Badge,
        BarcodeScanner
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
