import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AuthService } from '../auth/auth.service';
import * as firebase from 'firebase/app';
import 'firebase/storage'
import { HttpClient } from '@angular/common/http';

// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { AngularFireDatabase, snapshotChanges } from '@angular/fire/database';
import { RealtimeApiService } from '../realtimeApi/realtime-api.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        public db: AngularFirestore,
        public auth: AuthService,
        public http: HttpClient,
        public angularfire: AngularFireDatabase,
        public rtdb: RealtimeApiService
    ) {

    }

    ///////////////////////////////
    // GENERIC CRUD API REQUESTS
    ///////////////////////////////
    getAllDocuments(collection: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.collection(collection)
                .get()
                .toPromise()
                .then((querySnapshot) => {
                    let arr = [];
                    querySnapshot.forEach(function (doc) {
                        var obj = JSON.parse(JSON.stringify(doc.data()));
                        obj.$key = doc.id
                        arr.push(obj);
                    });

                    if (arr.length > 0) {
                        resolve(arr);
                    } else {
                        resolve(null);
                    }


                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    getDocument(collection, documentId) {
        return new Promise((resolve, reject) => {
            this.db.collection(collection).doc(documentId)
                .get()
                .toPromise()
                .then((snapshot) => {
                    let doc = snapshot.data();
                    doc.$key = snapshot.id;
                    resolve(doc);
                }).catch((error: any) => {
                    reject(error);
                });
        })
        // return this.firestore.collection('songList').doc(songId);
    }

    deleteDocument(collectionName: string, docID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .delete()
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    addDocument(collectionName: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.collection(collectionName).add(dataObj)
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    updateDocument(collectionName: string, docID: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .update(dataObj)
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    setDocument(collectionName: string, docID: string, dataObj: any) {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .set(dataObj)
                .then((obj: any) => {
                    resolve(obj)
                })
                .catch((error: any) => {
                    reject(error);
                })
        })
    }

    ///////////////////////////////
    /////////////CUSTOM API REQUESTS
    ///////////////////////////////
    getCollectionRef(collection) {
        return this.db.collection(collection, ref => ref.orderBy('seats'));
    }

    getRef(collection) {
        return this.db.collection(collection);
    }

    approveFriendship(friendship_key, friend_key) {
        return new Promise((resolve, reject) => {
            let batch = this.db.firestore.batch();

            batch.update(this.db.firestore.collection(`accounts/${friend_key}/friendships`).doc(friendship_key), {
                'status': 'approved'
            });

            batch.update(this.db.firestore.collection(`accounts/${this.auth.token}/friendships`).doc(friendship_key), {
                'status': 'approved'
            });

            batch.commit().then(function () {
                resolve(true);
            });
        });
    }

    sendRequest(request) {
        return new Promise((resolve, reject) => {
            let batch = this.db.firestore.batch();
            let request_id = this.db.createId();
            request.users = [request.from_account, request.to_account];
            batch.set(this.db.firestore.collection(`requests/`).doc(request_id), request);

            batch.set(this.db.firestore.collection(`timelines`).doc(this.db.createId()), {
                activity_key: request_id,
                date: new Date(),
                account: this.auth.token,
                type: 'request'
            });

            if (request.privacy == 'public') {
                this.db.collection(`accounts/${this.auth.token}/friendships`).ref
                    .where('status', '==', 'approved')
                    .get()
                    .then(data => {
                        data.forEach(element => {
                            batch.set(this.db.firestore.collection(`timelines`).doc(this.db.createId()), {
                                activity_key: request_id,
                                date: new Date(),
                                account: element.data().friend,
                                type: 'request'
                            });
                        });

                        batch.commit().then(data => {
                            resolve(true);
                        });
                    });
            } else {
                batch.commit().then(data => {
                    resolve(true);
                });
            }
        });
    }

    addFriendship(friend_key) {
        return new Promise((resolve, reject) => {
            let batch = this.db.firestore.batch();
            let friendship_id = this.db.createId();

            batch.set(this.db.firestore.collection(`accounts/${friend_key}/friendships`).doc(friendship_id), {
                status: 'awaiting_approval',
                friend: this.auth.token,
            });

            batch.set(this.db.firestore.collection(`accounts/${friend_key}/notifications`).doc(friendship_id), {
                type: 'friend_request',
                from: this.auth.token,
                date: new Date(),
                new: true
            });

            batch.set(this.db.firestore.collection(`accounts/${this.auth.token}/friendships`).doc(friendship_id), {
                status: 'pending_approval',
                friend: friend_key
            });

            batch.commit().then(data => {
                resolve(true);
            });
        });
    }

    sendPayment(payment, is_request, user_key) {
        return new Promise((resolve, reject) => {
            console.log("payment -->> ", payment)

            let batch = this.db.firestore.batch();
            let payment_id = this.db.createId();

            if (is_request) {
                batch.update(this.db.firestore.collection(`requests`).doc(is_request.$key), {
                    status: 'paid',
                    payment: payment_id
                });
                payment.request = is_request.$key;
            }

            payment.from_account = this.auth.token;
            payment.to_account = user_key;
            payment.date = new Date();
            payment.type = 'p2p';
            payment.users = [this.auth.token, user_key];

            // se agrega el pago
            batch.set(this.db.firestore.collection(`timelines`).doc(this.db.createId()), {
                activity_key: payment_id,
                date: new Date(),
                account: this.auth.token,
                type: 'payment'
            });

            if (payment.payment_type == 'balance') {
                batch.update(this.db.firestore.collection(`accounts/`).doc(payment.from_account), { balance: payment.from_balance });
            }

            this.getDocument('accounts', payment.to_account).then(account_result => {
                let balance = account_result['balance'];
                if (!balance) {
                    balance = 0;
                }

                balance += payment.amount;

                batch.update(this.db.firestore.collection(`accounts/`).doc(payment.to_account), { balance: balance });

                payment.to_balance = balance;

                batch.set(this.db.firestore.collection(`payments/`).doc(payment_id), payment);

                this.db.collection(`accounts/${this.auth.token}/friendships`).ref
                    .where('status', '==', 'approved')
                    .get()
                    .then(data => {

                        if (payment.privacy == 'public') {
                            data.forEach(element => {
                                batch.set(this.db.firestore.collection(`timelines`).doc(this.db.createId()), {
                                    activity_key: payment_id,
                                    date: new Date(),
                                    account: element.data().friend,
                                    type: "payment"
                                });
                            });
                        }

                        batch.commit().then(data => {

                            let conversationId;
                            this.rtdb.getConversationId(user_key).query.once('value', snapshot => {
                                // if(snapshot.val().hasOwnProperty('conversationId')){

                                conversationId = snapshot.val().conversationId;
                                var message = [];

                                if (payment.amountPaidSplit) {
                                    message.push({
                                        date: new Date().toString(),
                                        sender: this.auth.token,
                                        type: 'send-transaction',
                                        message: payment.amount + payment.amountPaidSplit
                                    });
                                } else {
                                    message.push({
                                        date: new Date().toString(),
                                        sender: this.auth.token,
                                        type: 'send-transaction',
                                        message: payment.amount
                                    });
                                }

                                if (conversationId) {
                                    // let message = {
                                    //     date: new Date().toString(),
                                    //     sender: this.auth.token,
                                    //     type: 'send-transaction',
                                    //     message: payment.amount
                                    // };
                                    let message2 : any
                                    if (payment.amountPaidSplit) {
                                        message2 = {
                                            date: new Date().toString(),
                                            sender: this.auth.token,
                                            type: 'send-transaction',
                                            message: payment.amount + payment.amountPaidSplit
                                        };
                                    } else {
                                        message2 = {
                                            date: new Date().toString(),
                                            sender: this.auth.token,
                                            type: 'send-transaction',
                                            message: payment.amount
                                        };
                                    }
                                    this.rtdb.addMessage(conversationId, message2);
                                    resolve(true);
                                } else {
                                    // New Conversation with friend.
                                    var users = [];
                                    users.push(this.auth.token);
                                    users.push(user_key);
                                    // Add conversation.
                                    this.angularfire.list('conversations').push({
                                        dateCreated: new Date().toString(),
                                        messages: message,
                                        users: users
                                    }).then((success) => {
                                        let conversationId = success.key;

                                        // Add conversation reference to the users.
                                        this.angularfire.object('/accounts/' + this.auth.token + '/conversations/' + user_key).update({
                                            conversationId: conversationId,
                                            messagesRead: 1
                                        });
                                        this.angularfire.object('/accounts/' + user_key + '/conversations/' + this.auth.token).update({
                                            conversationId: conversationId,
                                            messagesRead: 0
                                        });
                                        resolve(true);
                                    });
                                }
                                // }

                            });

                        });
                    });
            })
        })
    }

    uploadImage(image) {
        return new Promise((resolve, reject) => {

            let storageRef = firebase.storage().ref();
            const filename = this.generateUUID();
            let imageRef = storageRef.child(`users/${this.auth.token}/${filename}.jpg`);

            imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                .then(data => {
                    let photoData = {};
                    photoData['fullPath'] = data.metadata.fullPath;
                    photoData['size'] = data.metadata.size;
                    photoData['contentType'] = data.metadata.contentType;
                    photoData['md5Hash'] = data.metadata.md5Hash;
                    photoData['bucket'] = data.metadata.bucket;
                    photoData['updated'] = data.metadata.updated;
                    imageRef.getDownloadURL().then(data => {
                        photoData['downloadURL'] = data;
                        this.updateDocument('accounts', this.auth.token, { profilePicture: photoData }).then(data => {
                            resolve(photoData);
                        }, err => {
                            reject(err);
                        });
                    }, err => {
                        reject(err)
                    });
                })
        })
    }

    private generateUUID(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    sendPush(title, message, push_keys) {
        return new Promise((resolve, reject) => {
            const post_data = {
                'app_id': 'edf12374-085e-4a7c-a310-354089e9fff0',
                'headings': {
                    'en': title
                },
                'contents': {
                    'en': message
                },
                "include_player_ids": push_keys,
            }

            this.http.post('https://onesignal.com/api/v1/notifications', post_data)
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    error(error);
                });
        });
    }

}




