import { Component, OnInit } from '@angular/core';
import { ModalController, ActionSheetController, NavParams, LoadingController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {

    account: any;
    accountForm: FormGroup;

    constructor(
        public modalCtrl: ModalController,
        public api: ApiService,
        private camera: Camera,
        public actionSheetController: ActionSheetController,
        public navParams: NavParams,
        private auth: AuthService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController
    ) {
        this.accountForm = this.formBuilder.group({
            fullname: ['', [Validators.required,Validators.pattern(/^[A-Za-z\s\u00E0-\u00FC]*$/)]],
            username: ['', [Validators.required]],
            estado: ['', [Validators.required]],
            dui: ['', [Validators.required,Validators.minLength(10)]],
            //dui: ['', [Validators.required,Validators.minLength(10)]],
            birthdate: ['', [Validators.required]],
            //email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required,Validators.minLength(9)]],
            gender: ['', []],
        });
        

        this.api.getRef('accounts').doc(this.auth.token).ref
            .onSnapshot(snapshot => {
                let account = snapshot.data();
                account.key = snapshot.id;
                this.account = account;
                this.accountForm.controls['fullname'].setValue(account.fullname);
                this.accountForm.controls['username'].setValue(account.username);
                //this.accountForm.controls['email'].setValue(account.email);
                if (account.phone) {
                    this.accountForm.controls['phone'].setValue(account.phone);
                }
                if (account.gender) {
                    this.accountForm.controls['gender'].setValue(account.gender);
                }
                if (account.estado) {
                    this.accountForm.controls['estado'].setValue(account.estado);
                }
                if (account.dui) {
                    //console.log(account.dui);
                    //var decodeDui = atob(account.dui);
                    //console.log(decodeDui);
                    
                    this.accountForm.controls['dui'].setValue(account.dui);
                    //this.accountForm.controls['dui'].setValue(decodeDui);
                }
                if (account.birthdate) {
                    this.accountForm.controls['birthdate'].setValue(account.birthdate);
                }
            });
    }

    ngOnInit() { }


    dismiss() {
        this.modalCtrl.dismiss();
    }

    async selectImageProfile() {

        const actionSheet = await this.actionSheetController.create({
            cssClass: 'actionSheet',
            header: 'Change Profile Photo',
            buttons: [{
                text: 'Remove Current Photo',
                role: 'destructive',
                handler: () => {
                    console.log('Delete clicked');
                }
            },
            {
                text: 'Take Photo',
                handler: () => {
                    console.log('Take Photo');
                }
            },
            {
                text: 'Choose from Library',
                handler: () => {

                    const options: CameraOptions = {
                        destinationType: this.camera.DestinationType.DATA_URL,
                        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
                        encodingType: this.camera.EncodingType.JPEG,
                        mediaType: this.camera.MediaType.PICTURE,
                        allowEdit: true,
                        targetWidth: 500,
                        targetHeight: 500,
                    }
                    this.camera.getPicture(options).then((imageData) => {
                        let base64Image = 'data:image/jpeg;base64,' + imageData;
                        this.api.uploadImage(base64Image).then(data => {
                            console.log(data);
                        })
                    }, (err) => {
                        // Handle error
                    });

                }
            },
            {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                    console.log('Cancel clicked');
                }
            }]
        });
        await actionSheet.present();

    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    done() {
        this.loader().then(loader => {
            loader.present().then(() => {
                console.log( this.accountForm.value );
                //let valDui=this.accountForm.controls['dui'].value;
                //console.log(valDui);
                //
                //var encodeDui = btoa(valDui);
                //console.log(encodeDui);
                //
                //this.accountForm.controls['dui'].setValue(encodeDui);
                this.api.updateDocument('accounts', this.auth.token, this.accountForm.value).then(data => {
                    loader.dismiss();
                    this.dismiss();
                });

            })
        })
    }
}