<ion-content class="ion-text-center ion-padding" color="fondo">

    <ion-card-subtitle>
        <!-- @configuraciones -->
    </ion-card-subtitle>

    <ion-item detail="false" lines="full" routerLink="/code-qr" (click)="dismiss()">
        <ion-label color="text-primary">
            Mi código QR
        </ion-label>
        <ion-icon color="text-primary" slot="end" name="qr-code-outline"></ion-icon>
    </ion-item>

    <ion-item detail="false" lines="full" routerLink="/activity" (click)="dismiss()">
        <ion-label color="text-primary">
            Actividad
        </ion-label>
        <ion-icon color="text-primary" slot="end" name="calendar-outline"></ion-icon>
    </ion-item>

    <ion-item detail="false" lines="full" routerLink="/payment_methods" (click)="dismiss()">
        <ion-label color="text-primary">
            Metodos de pago
        </ion-label>
        <ion-icon color="text-primary" name="card-outline" slot="end"></ion-icon>
    </ion-item>

    <ion-item detail="false" lines="full" (click)="editProfile()">
        <ion-label color="text-primary">
            Editar perfil
        </ion-label>
        <ion-icon color="text-primary" slot="end" name="person-outline"></ion-icon>
    </ion-item>

    <ion-item detail="false" lines="full" routerLink="/settings" (click)="dismiss()">
        <ion-label color="text-primary">
            Configuraciones
        </ion-label>
        <ion-icon color="text-primary" name="settings-outline" slot="end"></ion-icon>
    </ion-item>

    <ion-item lines="full" (click)="logout()">
        <ion-label color="text-primary">
            Cerrar Sesión
        </ion-label>
        <ion-icon color="text-primary" name="log-out-outline" slot="end"></ion-icon>
    </ion-item>

    <!--
        <img src="assets/shapes/shape2.svg" alt="" class="back-shape">
    -->

</ion-content>