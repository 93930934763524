<ion-header>
    <ion-toolbar color="light">
        <ion-title color="tertiary">
            <div class="interactive-line"></div>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismiss()" color="dark">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <p style="text-align: justify;">POLÍTICA DE PRIVACIDAD</p>
    <p style="text-align: justify;">
    <strong>GENERALIDAD</strong>
     es una empresa subsidiaria de Premier Distributions S.A. de C.V., una empresa de naturaleza mercantil con domicilio en San Salvador, El Salvador y manteniendo operaciones de comercialización y distribución de productos en Centro América y otros países en los que en el futuro decide operar bajo su nombre comercial comprometiendo su protección de privacidad.
    A continuación, le exponemos como Pagï administrará sus datos como “usuario”, y como protegeremos su privacidad. Pagï recopila información acerca de usted antes de acceder o usar nuestra aplicación móvil y otros productos y servicios a través de terceros.
    <br>
    <strong>USO DE INFORMACIÓN</strong>
    Pagï podrá hacer uso de la información que recopilamos sobre usted como usuario para:
    Prestar, activar, mantener, administrar y mejorar nuestros Servicios como, por ejemplo, facilitar los pagos, enviar recibos o facturas, proporcionar productos y servicios que usted solicite (y enviar información relacionada o comunicaciones comerciales), desarrollar nuevas funciones, desarrollar opciones de seguridad, autenticar usuarios y enviar actualizaciones de productos y mensajes administrativos.
    Realizar operaciones internas, como, por ejemplo, mantener protegida la aplicación, prevenir el fraude y el abuso de nuestros Servicios; solucionar los errores de software y problemas de funcionamiento; llevar a cabo análisis de datos, pruebas e investigación; controlar y analizar las tendencias de uso y de actividad; verificar el cumplimiento de los términos y condiciones que rigen el uso de nuestros Servicios (incluyendo la monitorización de mensajes privados a través del servicio de mensajería privada.
    Enviar comunicaciones que han sido solicitadas por el Usuario o que puede ser de su interés, incluida la información sobre los productos, servicios, promociones, noticias y eventos, y cuando esté permitido y de conformidad con las leyes locales aplicables; y para tramitar concursos, sorteos u otras entradas de promociones y cumplir con los premios relacionados.
    Personalizar y mejorar los Servicios, incluyendo proporcionar o recomendar funciones, contenido, conexiones sociales, referencias y anuncios.
    Otros usos.
    <br>
    <strong>RECOPILACIÓN DE DATOS E INFORMACIÓN</strong>
    Recopilamos la información que usted nos proporciona directamente, por ejemplo, cuando crea o modifica su cuenta, solicita o usa nuestros Servicios o productos, se pone en contacto con atención al cliente o cuando se comunica de cualquier otra forma con nosotros en Pagï. Esta información puede incluir, pero no limitarse a: nombre, correo electrónico, número de teléfono, dirección postal, foto del perfil, género, estado civil, fecha de cumpleaños, método de pago, ciertos datos de tarjeta de crédito (referirse a nuestras políticas de seguridad), los artículos solicitados para servicios de entrega, notas de entrega, el contenido de la comunicación, metadatos y otra información personal que usted proporcione.
    Cuando utiliza nuestros Servicios, recopilamos información sobre usted en las siguientes categorías generales:
    Información de ubicación: Cuando, qué tan menudo y bajo qué circunstancias utiliza nuestros Servicios de entrega bajo demanda, recopilamos datos de ubicación exacta. Si usted permite a la aplicación de Pagï acceder a los servicios de ubicación a través del sistema de permisos que utiliza su sistema operativo para móviles, también podremos recopilar la ubicación exacta de su dispositivo cuando la aplicación se esté ejecutando. También podemos obtener su ubicación aproximada a partir de su dirección IP.
    Información de la operación: Recopilamos datos relacionados con el uso de nuestros Servicios, incluyendo el tipo de servicio solicitado, la fecha y el momento de la prestación del servicio, cantidad cargada, distancia recorrida, tiempo de entrega y otros detalles relacionados con la operación.
    Uso e información de preferencias: Recopilamos información sobre cómo usted y los visitantes del sitio interactúan con nuestros Servicios y/o productos, preferencias expresadas y configuraciones seleccionadas.
    Información del ordenador: Cuando usted interactúa con los Servicios, recopilamos los registros del servidor, que pueden incluir información como direcciones IP del dispositivo, fechas y horas de acceso, funciones de la aplicación utilizadas, páginas visitadas, bloqueos de la aplicación y otra actividad del sistema, tipo y versión de navegador, sistema operativo y el sitio de terceros o servicio que estaba utilizando antes de interactuar con nuestros Servicios.
    <br>
    <strong>INFORMACIÓN DE CUENTA</strong>
    Usted puede acceder a la información de su cuenta en cualquier momento, así como a solicitar la actualización, modificación y eliminación de la información contenida en ella, de forma gratuita. Si desea desactivar su cuenta, envíenos un mensaje de correo electrónico a contacto@Pagï.app. Tenga en cuenta que en algunos casos podemos retener cierta información sobre usted como lo requiere la ley o para fines comerciales legítimos en la medida permitida por la ley. Por ejemplo, si usted tiene un crédito o deuda pendiente en su cuenta, o si creemos que ha cometido fraude o incumplido nuestros Términos y Condiciones, o de haber cualquier procedimiento legal actual o prospectivo.
    <br>
    <strong>ACTUALIZACIONES, CAMBIOS Y REVISIONES DE POLITICA DE PRIVIDAD</strong>
    Pagï se reserva el derecho de modificar o actualizar estas Políticas de Privacidad a consideración y conveniencia. Si hacemos cambios importantes en la forma en que tratamos su información personal, se lo notificaremos a través de los Servicios o por otros medios, como el correo electrónico o a través del servicio de mensajería privada, o bien se remitirá una actualización de la presente política. El uso continuado de los Servicios después de dicha notificación constituye su consentimiento a los cambios.
    Le recomendamos que revise periódicamente nuestra Política de Privacidad para tener la información más reciente sobre nuestras prácticas.
    <br>
    <strong>POLÍTICA DE SEGURIDAD DE DATOS E INFORMACIÓN</strong>
    A través de nuestra plataforma no se guarda información de tarjeta de crédito ni débito de ninguno de los tarjetahabientes. Esta información, en ningún momento será retenida, divulgada o registrada, si no que utiliza procesadores terceros de pago. La única información que pasa hacia la plataforma del tercero son los datos del propietario, correo electrónico y monto total del importe a pagar.
    La tarjeta es guardada exclusivamente en el dispositivo de forma segura. Dicha información está sujeta a nuestra Política de Privacidad.
    <br>
    <strong>ACTUALIZACIONES, CAMBIOS Y REVISIONES DE POLÍTICA SEGURIDAD</strong>
    Pagï se reserva el derecho de modificar o actualizar estas Políticas de Transmisión de datos de Tarjetas de Crédito. Si hacemos cambios importantes en la forma en que tratamos su información personal, es probable que se lo notificaremos a través de los Servicios o por otros medios, como el correo electrónico o a través del servicio de mensajería privada, o bien se remitirá una actualización de la presente política. El uso continuado de los Servicios después de dicha notificación constituye su consentimiento a los cambios. Le recomendamos que revise periódicamente nuestra Política de Privacidad para tener la información más reciente sobre nuestras prácticas.
    </p>
    <p style="text-align: justify;">TÉRMINOS Y CONDICIONES</p>
    <p style="text-align: justify;">
        <strong>RELACIÓN CON EL USUARIO</strong>
        Los presentes Términos y Condiciones de uso dictan las políticas a seguir para la
        utilización de la aplicación móvil, así como un “acuerdo” entre USTED y
        Pagï de tal forma que se regula todo el uso, acceso, productos y cobros
        relacionados a los servicios que presta Pagï.
        Pagï, una empresa subsidiaria registrada legalmente como Premier
        Distributions S.A de C.V. con domicilio en San Salvador, El Salvador. Todo acceso
        y uso que usted haga, como persona natural o jurídica en la aplicación, productos
        y servicios que ofrece Pagï.APP estarán sujetos a las leyes y disposiciones
        legales del Estado de registro de la compañía con sede en El Salvador.
        Por favor lea detenidamente los siguientes términos y condiciones antes de
        acceder o utilizar los servicios, con el propósito de asegurarse de que usted
        entiende cada disposición.
        Si usted no acepta estas Condiciones, no podrá acceder o usar los servicios.
        Estas condiciones sustituyen expresamente los acuerdos o compromisos previos
        con usted. Pagï se reserva el derecho de poner fin de inmediato a estas
        condiciones o cualquiera de los servicios respecto de usted o, en general, dejar de
        ofrecer o denegar el acceso a los servicios y / o productos o cualquier parte de
        ellos, en cualquier momento en caso que exista algún incumplimiento a las
        condiciones. <br>
        <strong>MODIFICACIÓN, ACTUALIZACIÓN Y CAMBIOS</strong>
        Pagï podrá modificar los presentes términos y condiciones de uso relativos
        a los servicios que brinda la aplicación (app) cuando lo considere conveniente a fin
        de mantener actualizadas todas las disposiciones y reglamentos pertinentes a las
        leyes que rigen su operación y comercialización.
        Cualquier modificación, actualización y/o cambio será notificado vía correo
        electrónico y entrará en vigencia inmediatamente a su notificación y su vigencia
        será una vez transcurridos 14 días desde su publicación.
        Usted como usuario entiende y se da por enterado que será el responsable en
        todo momento de mantenerse actualizado con cada uno de los términos y
        condiciones referidos en este documento.
        <br>
        <strong>
            POLÍTICAS DE ENTREGA
        </strong>
        Pagï es una aplicación móvil que permite a los usuarios comprar productos
        y servicios que en la plataforma se ofrecen. Pagï gestionará a nombre del
        usuario la compra de productos y servicios de distribución propia y proveedores
        (productos o servicios de terceros) los cuales estarán presentados bajo el menú
        de Pagï a disponibilidad de los usuarios.
        Pagï procesará su orden y gestionará el pago y la entrega de los productos
        ya sea en tiendas o establecimientos inscritos o con entrega a domicilio el costo del envío dependerá de la ubicación geográfica del cliente. Para
        detalles vea Reservar y Comprar productos.
        El Usuario reconoce que Pagï solamente es una plataforma de tecnología
        que ofrece exclusivamente servicio de compra de productos y servicios a través de
        la misma y que no es una empresa de transporte o logística, por lo que cualquier
        servicio de entrega a domicilio será provista por un proveedor (productos o
        servicios de terceros) siempre en cumplimiento de cualquier requerimiento legal o
        permiso que estos necesiten para proveer el servicio para operar, así como
        cumplir con toda la legislación nacional aplicable. 
        Las entregas serán realizadas de acuerdo al horario de labores publicado en nuestra cuenta. Es importante mantener atención debido a las medidas de cambio de horario dispuestos por las autoridades gubernamentales pues pueden cambiar. Al momento de colocar tu orden el pedido será entregado durante la próxima hora en la que fue recibida la orden. Para los clientes de negocios (Bares/Restaurantes/Hoteles) antes de enviar tu pedido, te contactaremos vía telefónica para cerciorarnos que tú, o una de las personas que autorizaste para recibir la mercancía, estén disponibles para recibirlo. Si tu pedido fue entregado en la dirección acordada y no fue recibido porque no estuviste disponible, entonces debes buscar tu pedido por nuestro local.
        <br>
        <strong>
            LICENCIA
        </strong>
        En base a estos términos y condiciones, Pagï, le otorga a los usuarios un
        permiso de uso, es decir, una licencia limitada, no exclusiva, no sublicenciable,
        revocable y no transferible para hacer uso de las aplicaciones en su dispositivo
        móvil personal solo en relación a los usos de los servicios que sean necesarios
        para el uso de la aplicación; y el acceso y uso de cualquier contenido, información
        y material relacionado que pueda ponerse a disposición o a través de los servicios,
        en cada caso solo para uso personal, no comercial. Para más información le
        invitamos a ver nuestra Política de Privacidad. <br>
        <strong>
            PRODUCTOS O SERVICIOS DE TERCEROS
        </strong>
        Pagï podrá hacer uso de proveedores de productos y servicios, de tal
        forma que la plataforma pueda ofrecer la venta libre de los productos que se
        encuentren disponibles para compra y/o reserva. De igual forma Pagï
        podrá obtener productos de terceros y transferirlos y registrarlos bajo el nombre
        comercial y de registro de la marca para su propia comercialización. Los productos
        y servicios podrán ser combinados dentro de la plataforma tanto propios como de
        terceros y serán comercializados, vendidos y/o reservados bajo el mismo proceso
        de compra y/o reserva de los mismo. Para mas información sobre el proceso de
        compra le invitamos a ver Reservar y Comprar productos <br>

        <strong>
            CUENTAS DE USUARIO
        </strong>

        Usted debe registrarse y mantener activa una cuenta personal de usuario de los
        servicios (“Cuenta”). Para obtener una cuenta debe tener como mínimo 18 años.
        El registro de la cuenta le requiere que comunique a Pagï determinada
        información personal, como su nombre, dirección, número de identificación
        tributaria, número de Documento Único de Identidad, número de teléfono móvil,
        así como por lo menos un método de pago válido (bien una tarjeta de crédito o
        bien un socio de pago aceptado). Usted se compromete a mantener la información
        en su cuenta de forma exacta, completa y actualizada. Si no mantiene la
        información de cuenta de forma exacta, completa y actualizada, incluso el tener un
        método de pago inválido o que haya vencido, podrá resultar en su imposibilidad
        para acceder y utilizar los servicios o en la resolución por parte de Pagï de
        estos Términos y Condiciones, sin responsabilidad para Pagï de darlo por
        finalizado automáticamente. Usted es responsable de toda la actividad que ocurre
        en su cuenta y se compromete a mantener en todo momento de forma segura y
        secreta el nombre de usuario y la contraseña de su cuenta. A menos que
        Pagï permita o modifique los presentes términos, lo cual será notificado por
        escrito, usted solo puede poseer una cuenta asociada al mismo número de
        Documento Único de Identidad.
        Pagï se reserva el derecho de poner a disposición diferentes tipos de
        cuentas para distintos tipos de usuarios. Pagï podrá, a su sola discreción,
        crear, mantener y administrar estos distintos tipos de cuenta. Su cuenta le da
        acceso para el uso de los servicios y funcionalidades que podemos crear y
        mantener de tiempo en tiempo bajo nuestra sola discreción. Si al abrir una cuenta
        con nosotros, usted lo hace en nombre de una persona jurídica, tal y como una
        sociedad, asociación, fundación u otro tipo entidad, usted declara y garantiza tener
        las facultades de acuerdo con la legislación aplicable necesarias para aceptar y
        por ende obligar a dicha persona jurídica a los presentes Términos y Condiciones.
        Pagï se reserva el derecho de solicitar la documentación legal que
        compruebe la personería jurídica con la cual actúa en nombre y representación de
        dicha entidad, la cual deberá estar vigente y con suficientes facultades para actuar
        en nombre de dicha persona jurídica. <br>
        <strong>
            USO DE LA INFORMACIÓN DEL USUARIO
        </strong>
        Pagï podrá, a su sola discreción, permitirle cuando considere oportuno, que
        envíe, cargue, publique o de otro modo ponga a disposición de Pagï a
        través de los servicios contenido e información de texto, audio y/o visual, incluidos
        comentarios y opiniones relativos a los Servicios. Todo Contenido de Usuario
        facilitado por usted seguirá siendo de su propiedad, no obstante, por medio de los
        presentes términos el usuario faculta y exime de responsabilidad a Pagï de
        utilizar el Contenido de usuario otorgando por tanto una licencia mundial,
        perpetua, irrevocable, transferible, libre de regalías, con derecho a sublicenciar,
        usar, copiar, modificar, crear obras derivadas, distribuir, exhibir públicamente,
        presentar públicamente o de otro modo explotar de cualquier manera dicho
        Contenido de usuario en todos los formatos y canales de distribución, conocidos ahora o ideados en un futuro
        (incluidos en relación con los Servicios y el negocio
        de Pagï y en sitios y servicios de terceros), sin más aviso o consentimiento
        de usted y sin requerirse el pago a usted o a cualquier otra persona o entidad. De
        igual forma el USUARIO hace constar que en pleno uso de sus facultades faculta
        a Pagï a utilizar la información de su propiedad y que ha puesto a su
        disposición, para los usos y fines contemplados en los presentes términos y
        condiciones. <br>
        <strong>
            CONTENIDO DE LA INFORMACIÓN
        </strong>
        Usted declara y garantiza que: a) es el único y exclusivo propietario de todo el
        Contenido de Usuario o que tiene todos los derechos, licencias, consentimientos y
        permisos necesarios para otorgar a Pagï la licencia y permiso de uso al
        Contenido de Usuario como se ha establecido anteriormente; y b) ni el Contenido
        de Usuario ni su presentación, carga, publicación o puesta a disposición de otro
        modo de dicho Contenido de Usuario, ni el uso por parte de Pagï del
        Contenido de Usuario como está aquí permitido, infringirán, malversarán o violarán
        la propiedad intelectual o los derechos de propiedad de un tercero o los derechos
        de publicidad o privacidad o resultarán en la violación de cualquier ley o
        reglamento aplicable.
        Usted acuerda no proporcionar Contenido de Usuario que sea difamatorio,
        calumnioso, odioso, violento, obsceno, pornográfico, ilícito o de otro modo
        ofensivo, de conformidad con la legislación aplicable o como determine
        Pagï, a su sola discreción, tanto si dicho material pueda estar protegido o
        no por la Ley. Pagï podrá, a su sola discreción y en cualquier momento y
        por cualquier motivo, sin avisarle previamente, revisar, controlar o eliminar
        Contenido de Usuario, pero sin estar obligado a ello.
        <br>
        <strong>
            ACCESO A LA RED Y DISPOSITVOS
        </strong>
        Usted es responsable de obtener el acceso a la red de datos necesario para utilizar
        los servicios proporcionados por Pagï. En cualquier momento podrán
        aplicarse las tarifas y tasas de datos y mensajes de su red móvil si usted accede o
        utiliza los Servicios desde un dispositivo móvil y usted será responsable de dichas
        tarifas y tasas. Usted es responsable de adquirir y actualizar el hardware
        compatible con los dispositivos necesarios para acceder y utilizar los Servicios y
        Aplicaciones y cualquier actualización de estos. Pagï no garantiza que los
        Servicios, o cualquier parte de estos, funcionen en cualquier hardware o
        dispositivo particular. Además, los Servicios podrán ser objeto de disfunciones o
        retrasos inherentes al uso de Internet y de las comunicaciones electrónicas.
        MENSAJERIA (SMS)
        Pagï hace de su conocimiento que, al crear una Cuenta, usted acepta que
        Pagï le pueda enviar mensajes de texto informativos (SMS) como parte de
        la actividad comercial normal de su uso de los Servicios. Usted podrá solicitar la
        no recepción de mensajes de texto informativos (SMS) de Pagï en

        cualquier momento, enviando un correo electrónico a contacto@Pagï.app,
        señalando que no desea recibir más dichos mensajes, junto con el número de
        teléfono del dispositivo móvil que recibe los mensajes. Usted reconoce que
        solicitar la no recepción de mensajes de texto informativos (SMS) podrá afectar al
        uso que usted haga de los Servicios.
        <br>
        <strong>
            RESERVAR Y COMPRAR PRODUCTOS
        </strong>
        Pagï le permite reservar y comprar productos directamente desde la
        aplicación movil, únicamente usted tiene que buscar el producto en el menú de
        productos, solicitar la cantidad deseada (considerando la disponibilidad existente)
        y podrá efectuar una reserva del producto o realizar la compra en el momento.
        Cuando usted reserva un producto, tendrá la opción de realizar el pago dentro de
        las próximas 24 horas y posterior al pago, recoger su producto en tiendas o
        solicitar su envío. Si usted no realiza ninguna acción pasadas las 24 horas desde
        su reserva, usted perderá la reserva del producto y estará nuevamente disponible
        para una nueva reserva o venta, sin responsabilidad alguna para Pagï.
        Usted puede de la misma manera realizar la compra del producto desde su tarjeta
        de crédito o débito registrada en Pagï, y tendrá la opción de recoger el
        producto en tienda o solicitar envío a la dirección que indique.
        Pagï le enviará a su correo electrónico el comprobante de compra con el
        detalle de cargos realizados, los cuales podrán incluir cargos adicionales por
        servicios de envío. <br>
        <strong>
            PROMOCIONES
        </strong>
        Pagï podrá, a su sola discreción, crear códigos promocionales que
        podrán ser canjeados por elementos o beneficios relacionados con los servicios
        o productos, con sujeción a cualquier condición adicional que Pagï
        establezca sobre la base de cada código promocional (“Códigos
        promocionales”).
        Usted acuerda que los Códigos promocionales: a) deben usarse para la
        audiencia y el propósito deseado, y de manera lícita; b) no podrán duplicarse,
        venderse o transferirse de ninguna manera, o ponerse a disposición del público
        general (tanto si se publican en un foro público como de otra forma), a menos
        que sea con el permiso de Pagï; c) podrán ser invalidados por
        Pagï en cualquier momento por cualquier motivo sin responsabilidad
        para Pagï; d) podrán usarse solo conforme a las condiciones específicas
        que Pagï establezca para dicho Código promocional; e) no son válidos
        como efectivo; y f) podrán caducar antes de que usted los utilice. Pagï se
        reserva el derecho de retener o deducir el crédito u otros elementos o beneficios
        obtenidos a través de la utilización de los Códigos promocionales por usted o
        cualquier otro usuario en el caso de que Pagï determine o crea que el

        uso o el canje de los Códigos promocionales fue de modo erróneo, fraudulento,
        ilegal o infringiendo las condiciones del Código promocional o las presentes
        Condiciones.
        <br>
        <strong>
            PRODUCTOS
        </strong>
        Pagï pondrá a su disposición un catálogo de productos los cuales podrán
        ser propios o por distribuidores de productos externos (productos o servicios de
        terceros), Pagï contará en su catálogo con productos de distrubición propia
        por la compañía Premier Distributions S.A de C.V.
        <br>
        <strong>
            PRODUCTOS O SERVICIOS DE TERCEROS
        </strong>
        Para la distribución de la reserva y/o compra de productos o servicios Pagï
        podrá integrar productos de terceros en sus catálogos y al mismo tiempo ofrecer
        servicios de terceros como, por ejemplo, para el servicio de tiendas o envíos.
        Para los productos o servicios de terceros, estos deberán cumplir con todas las
        disposiciones de calidad y especificaciones requeridas por Pagï de manera
        que su cumplan cada uno de los requisitos para la integracion en la plataforma.
        Para los servicios de envíos a domicilio, Pagï contará con la participación
        de servicio de terceros, los cuales podrán generar cargos extras en su compra por
        la solicitud del servicio prestado. Dicho cargo será administrado y generado a
        través de la plataforma de Pagï y cobrado directamente al usuario
        solicitante del servicio.
        <br>
        <strong>
            PAGOS Y CANCELACIONES
        </strong>
        Pagï le hace saber y al mismo tiempo usted entiende que el uso de los
        servicios de la aplicación móvil puede derivar en cargos por los servicios o
        productos que reciba por parte de Pagï o de un tercero bajo la cláusula de
        productos o servicios de terceros.
        Todo pago efectuado a través de la plataforma de Pagï será resposabilidad
        de usted al momento de efectuarlo, sin embargo, el usuario podrá visualizar el
        detalle de la orden de pago y usted será quien dará efectividad al mismo a traves
        del cargo a su tarjeta de crédito o débito.
        Todos los Cargos son pagaderos inmediatamente y el pago se facilitará por
        Pagï utilizando el método de pago preferido indicado en su Cuenta, y
        después de ello Pagï le enviará un recibo por correo electrónico o cualquier
        medio que éste disponga, lo cual se le hará de su conocimiento. Si se determina
        que el método de pago de su Cuenta principal ha caducado, es inválido o de otro
        modo no sirve para cobrarle, usted acepta que Pagï, como agente de cobro

        limitado del Tercero Proveedor, utilice un método de pago secundario en su
        Cuenta, si estuviera disponible.
        Si por algún motivo o circunstancia el Usuario cancela su pedido una vez se
        hubiere procedido al pago, y en el caso que dicha causa no fuere atribuible a
        Pagï, el usuario CONOCE y AUTORIZA sin perjuicio alguno ni
        responsabilidad de terceros a que Pagï le retenga de su tarjeta de débito o
        crédito la cantidad de la transacción efectuada por un periodo de TREINTA días
        hábiles (30). Por lo anterior, Pagï pondrá a disposición del Usuario la
        posibilidad de solicitar nuevamente el pedido cancelado, lo cual será efectivo
        únicamente en ese lapso de tiempo, a fin que el usuario solicite nuevamente el
        producto u otro producto de su elección, sin cargo adicional. Sin embargo, pasado
        el periodo de retención por cancelación se podrá efecuar un reembolso del cobro
        en base a la política de Reembolso.
        Al momento de efectuar una cancelación y/o solicitar un reembolso, Pagï
        podrá aplicar cargos administrativos por aquellos pedidos solicitados y cancelados
        por el usuario, los cuales serán expresados al usuario en el momento de hacer el
        cargo, asimismo el usuario dependerá en todo momento de la política de
        reembolso de su banca local.
        <br>
        <strong>
            POLÍTICAS DE REEMBOLSOS Y DEVOLUCIONES
        </strong>
        Pagï, en cualquier momento y a su sola discreción, se reserva el derecho
        de establecer, eliminar y/o revisar los cargos para alguno o todos los servicios o
        productos obtenidos a través del uso de la plataforma. Además, usted reconoce y
        acepta que los cargos aplicables podrán ser debitados directamente desde su
        cuenta de usuario a través de su tarjeta de crédito o débito registrada.
        Usted podrá solicitar un reembolso a través de la compra de productos y uso de
        servicios de Pagï vía correo electrónico contacto@Pagï.app.
        Cualquier reembolso solicitado por usted, deberá ser aprobado por Pagï y
        se le notificará vía correo electrónico en plazo máximo de TREINTA (30) días
        hábiles su resolución, el cual iniciará durante las próximas 24 horas posteriores a
        la recepción de su solicitud a través del correo electrónico contacto@Pagï.app.
        Pagï podrá efecutar cobros por recargos administrativos previamente
        establecidos y expresados al usuario en el momento de hacer el cargo.
        Pagï podrá efectuar el reembolso durante el plazo de los TREINTA (30)
        días hábiles posteriores a la notificación de la resolución. Sin embargo, usted
        deberá considerar la gestión de reembolso por parte de su banca local.
        <br>
        <strong>
            LIMITACIONES DE RESPONSABILIDAD Y GARANTIAS
        </strong>
        Pagï renuncia a toda declaración y garantía, expresa, implícita o
        estatutaria, no expresamente establecida en estos términos y condiciones,

        incluidas las garantías implícitas de comerciabilidad, idoneidad para un fin
        particular y no violación. Además, Pagï no hace declaración ni presta
        garantía alguna relativa a la fiabilidad, puntualidad, calidad, idoneidad o
        disponibilidad de los servicios o cualquiera de los servicios o bienes solicitados a
        través del uso de los servicios, o que los servicios no serán interrumpidos o
        estarán libres de errores. Pagï no garantiza la calidad, idoneidad,
        seguridad o habilidad de los terceros proveedores. Usted acuerda que todo riesgo
        derivado de su uso de los servicios y cualquier servicio o bien solicitado en
        relación con aquellos será únicamente suyo, en la máxima medida permitida por la
        ley aplicable.
        <br>
        <strong>INDEMNIDAD.</strong>
        Usted acuerda mantener indemnes y responder frente a Pagï y sus
        consejeros, directores, empleados y agentes por cualquier reclamación, demanda,
        pérdida, responsabilidad y gasto (incluidos los honorarios de abogados) que
        deriven de: a) su uso de los Servicios o productos obtenidos a través de su uso de
        los Servicios; b) su incumplimiento o violación de cualquiera de estas Condiciones;
        c) el uso por parte de Pagï de su Contenido de usuario; o d) su infracción
        de los derechos de cualquier tercero, incluidos Terceros proveedores.
        <br>
        <strong>
            RESPOSABILIDAD DE DAÑOS
        </strong>
        Pagï no será responsable de daños indirectos, incidentales, especiales,
        ejemplares, punitivos o emergentes, incluidos el lucro cesante, la pérdida de
        datos, la lesión personal o el daño a la propiedad, ni de perjuicios relativos, o en
        relación con, o de otro modo derivados de cualquier uso de los servicios, incluso
        aunque Pagï haya sido advertido de la posibilidad de dichos daños.
        Pagï no será responsable de cualquier daño, responsabilidad o pérdida
        que deriven de: a) su uso o dependencia de los servicios o su incapacidad para
        acceder o utilizar los servicios; o b) cualquier transacción o relación entre usted y
        cualquier tercero proveedor, aunque Pagï hubiera sido advertido de la
        posibilidad de dichos daños. Pagï no será responsable del retraso o de la
        falta de ejecución resultante de causas que vayan más allá del control razonable
        de Pagï.
        <br>
        <strong>
            NOTIFICACIONES
        </strong>
        Pagï podrá notificar por medio de una notificación general en los Servicios,
        mediante un correo electrónico enviado a su dirección electrónica en su Cuenta o
        por comunicación escrita enviada a su dirección, según lo dispuesto en su Cuenta.
        Usted podrá notificar a Pagï por comunicación escrita a la dirección de

        Pagï que aparece en la sección de “Contáctenos” de la aplicación. Si la
        notificación escrita es enviada a otra dirección, esta no será válida.
    </p>
</ion-content>