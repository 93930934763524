import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { CreditCardValidator } from 'ngx-credit-cards';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AlertController, ModalController, LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ToastController } from '@ionic/angular';
// import { TextMaskModule } from 'angular2-text-mask';

@Component({
    selector: 'app-add-payment-method',
    templateUrl: './add-payment-method.component.html',
    styleUrls: ['./add-payment-method.component.scss'],
})
export class AddPaymentMethodComponent implements OnInit {

    cardForm: FormGroup;
    type: any = 'unknown';
    mensajeCvv: any = '';
    cardCcv:any;


    public cardNumberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    public cardExpDateMask = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]
    public cardCvvMask = [/[0-9]/, /[0-9]/, /[0-9]/]

    constructor(
        public formBuilder: FormBuilder,
        public api: ApiService,
        private auth: AuthService,
        private http: HttpClient,
        public loadingCtrl: LoadingController,
        public alertController: AlertController,
        public modalController: ModalController,
        public toastController: ToastController,
    ) {
        this.cardForm = this.formBuilder.group({
            cardName: ['', [Validators.required, Validators.minLength(2),Validators.pattern(/^[A-Za-z\s\u00E0-\u00FC]*$/)]],
            cardNumber: ['', [Validators.required,Validators.minLength(19)]],
            cardType: ['', [Validators.required]],
            cardExpDate: ['', [Validators.required,Validators.pattern(/^(?:0?[1-9]|1[1-2])([\-/.])\d{2}$/)]],
            cardCvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
        });

        this.cardForm.get('cardNumber').valueChanges.subscribe(
            card => {
                this.getCreditCardType(card)
            }
        );
    }

    ngOnInit() {
    }

    dismiss() {
        this.modalController.dismiss()
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }


    addCard() {
        this.cardForm.value.cardNumber = this.cardForm.value.cardNumber.replace(/\s/g, '');
        this.cardForm.value.cardExpDate = this.cardForm.value.cardExpDate.replace('/', '');
        if (this.cardForm.value.cardCvv!="000" && this.cardForm.value.cardCvv!="0000") {
            this.loader().then(loader => {
                loader.present().then(() => {
                    let getData;
                        getData = `CardNumber=${this.cardForm.value.cardNumber}&ExpiryDate=${this.cardForm.value.cardExpDate}&reference=${this.auth.token}`;                   
                        let url = `https://payments.pagiapp.com/tokenization.php?${getData}`;
                        this.http.get(url)
                            .subscribe((response) => {
                                if (response) {
    
                                    this.api.addDocument(`accounts/${this.auth.token}/cards`, {
                                        cardNumber: this.cardForm.value.cardNumber.substring(0,6),
                                        cardNumberLast: this.cardForm.value.cardNumber.substr(this.cardForm.value.cardNumber.length - 4),
                                        added_date: new Date(),
                                        cardName: this.cardForm.value.cardName,
                                        type: this.cardForm.value.cardType,
                                        ExpiryDate: this.cardForm.value.cardExpDate,
                                        cardCvv: this.cardForm.value.cardCvv,
                                        token: response['token']
                                    }).then(data => {
                                        loader.dismiss();
                                        this.modalController.dismiss(data.id)
                                    })
                                } else {
                                    Swal.fire(
                                        'Error al agregar tu tarjeta',
                                        'Sucedio un error, puedes intentar nuevamente',
                                        'warning'
                                    )
                                    loader.dismiss();
                                }
    
                            }, err => {
                                console.log(err);
    
                                Swal.fire(
                                    'Error al agregar tu tarjeta',
                                    'Sucedio un error, puedes intentar nuevamente',
                                    'warning'
                                )
                                loader.dismiss();
                                this.dismiss();
                            });
    
                    
    
                })
            })
        }else{
            let contaCero = this.cardForm.value.cardCvv.length;
            this.toastController.create({
                
                message: `Número de Ccv no puede contener ${contaCero} ceros`,
                 duration: 2000,
                 color: 'danger'
             }).then(toast => {
                 toast.present();
             })
        }
        
    }

    getCreditCardExpDate(card=null,backspace=null) {
        console.log(card);
        this.cardCcv=card;
        if (backspace!=null) {
            let cardCcvDelete = document.getElementById("cardExpDate");
            //cardCcvDelete.innerHTML="";
            this.cardForm.controls['cardExpDate'].setValue("");
        }
     }
 
     soloNumeros(event: any) {
         var KeyID = event.keyCode;
         console.log(KeyID);
         if (KeyID==8 || KeyID==229) {
             this.getCreditCardExpDate(null,1)
         }  
     }
 
     getCreditCardType(card) {
         let accountNumber = card;
         //start without knowing the credit card type
         var result = "unknown";
         //first check for MasterCard
         if (/^5[1-5]/.test(accountNumber)) {
             result = "mastercard";
             this.type = result;
             this.cardForm.controls['cardType'].setValue(result);
             return result;
         }
         //then check for Visa
         else if (/^4/.test(accountNumber)) {
             result = "visa";
             this.type = result;
             this.cardForm.controls['cardType'].setValue(result);
             return result;
         }
         else if (/^3[47]/.test(accountNumber)) {
             result = "amex";
             this.type = result;
             this.cardForm.controls['cardType'].setValue(result);
             return result;
         }
         else {
             result = "unknown";
             this.cardForm.controls['cardType'].setValue('');
             this.type = "unknown";
             return result;
         }
     }

}
