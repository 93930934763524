import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';

@Component({
    selector: 'app-settings-menu',
    templateUrl: './settings-menu.component.html',
    styleUrls: ['./settings-menu.component.scss'],
})
export class SettingsMenuComponent implements OnInit {

    constructor(
        public modalController: ModalController,
        public auth: AuthService,
        private router: Router
    ) {

    }

    ngOnInit() {

    }

    dismiss() {
        this.modalController.dismiss();
    }

    logout() {
        this.modalController.dismiss('logout');
        // this.auth.logOut();
        // this.router.navigate(['login']);
        // this.dismiss();
    }

    async editProfile() {
        this.dismiss();
        const modal = await this.modalController.create({
            component: EditProfileComponent,
            animated: true,
        });
        return await modal.present();
    }

}
