import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController, LoadingController, AlertController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { Router } from '@angular/router';
import { AddFriendsSplitComponent } from '../add-friends-split/add-friends-split.component';
import { PaymentMethodsComponent } from 'src/app/components/payment-methods/payment-methods.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RequestPaggiComponent } from 'src/app/components/paggis/request-paggi/request-paggi.component';
import { PaymentConfirmationComponent } from '../payment-confirmation/payment-confirmation.component';

@Component({
    selector: 'app-split',
    templateUrl: './split.component.html',
    styleUrls: ['./split.component.scss'],
})
export class SplitComponent implements OnInit {
    requests: any = [];

    request: any;
    user: any;
    payment: any;
    card: any = false
    cardError: any = false;
    paymentForm: FormGroup;
    current_balance: any;
    friend: any;
    listFriendSplit: any = [];
    isSplit: any = true

    constructor(
        public modalController: ModalController,
        public navParams: NavParams,
        public api: ApiService,
        public popoverController: PopoverController,
        public loadingCtrl: LoadingController,
        public alertController: AlertController,
        public formBuilder: FormBuilder,
        private auth: AuthService,
        public router: Router
    ) {
        this.request = navParams.get('request');
        console.log("this.request -> ", this.request)
        this.user = navParams.get('user');
        console.log("this.user -> ", this.user)
        this.payment = navParams.get('payment');
        console.log("this.payment -> ", this.payment)

        this.paymentForm = this.formBuilder.group({
            description: [this.payment.comment],
            payment_type: ['balance'],
            privacy: ['public', [Validators.required]],
        });

        this.api.getRef(`requests`).ref
            // .where("split.$key", "==", "1RXs90EUF5VNu7OXzpSk")
            .where("split.$key", "==", this.request.$key)
            .onSnapshot(Snapshots => {
                if (Snapshots.size == 0) {
                    this.listFriendSplit = []
                }

                Snapshots.docChanges().forEach(change => {
                    if (change.type === "added") {
                        console.log("added: ", change.doc.data());
                        // change.doc.data().to_account
                        this.api.getRef('accounts').doc(change.doc.data().to_account).ref
                            .onSnapshot(snapshot => {
                                let account = snapshot.data();
                                account.$key = snapshot.id;
                                let request = change.doc.data()
                                request.$key = change.doc.id;
                                account.request = request
                                this.listFriendSplit.push(account);

                                for (var i = this.listFriendSplit.length - 1; i >= 0; i--) {
                                    if(this.listFriendSplit[i].$key == account.$key && !this.listFriendSplit[i].hasOwnProperty('request')){
                                        this.listFriendSplit.splice(i,1)
                                    }
                                }
                                // comprobando si hay algun usuario pendiente de pagar el split
                                let isSplitBoolean = false
                                for (var i = this.listFriendSplit.length - 1; i >= 0; i--) {
                                    if(this.listFriendSplit[i].request.status == "pending"){
                                        isSplitBoolean = true
                                        this.isSplit = true
                                        return
                                    }else{
                                        this.isSplit = false
                                    }
                                }
                                // if(isSplitBoolean){
                                //     this.isSplit = true
                                // }else{
                                //     this.isSplit = false
                                // }
                                console.log("this.isSplit --> ",this.isSplit)
                            });

                    }
                    if (change.type === "modified") {
                        console.log("modified: ", change.doc.data());
                        this.api.getRef('accounts').doc(change.doc.data().to_account).ref
                            .onSnapshot(snapshot => {
                                let account = snapshot.data();
                                account.$key = snapshot.id;
                                let request = change.doc.data()
                                request.$key = change.doc.id;
                                account.request = request
                                console.log("ANTES this.listFriendSplit -->>", this.listFriendSplit)
                                let listFriendSplit = this.listFriendSplit.filter(function (value, index, arr) {
                                    return value.$key != snapshot.id;
                                });
                                this.listFriendSplit = listFriendSplit
                                console.log("DESPUES this.listFriendSplit -->>", this.listFriendSplit)

                                this.listFriendSplit.push(account);
                                console.log(this.listFriendSplit)

                                let isSplitBoolean = false
                                for (var i = this.listFriendSplit.length - 1; i >= 0; i--) {
                                    if(this.listFriendSplit[i].request.status == "pending"){
                                        isSplitBoolean = true
                                        this.isSplit = true
                                        return
                                    }else{
                                        this.isSplit = false
                                    }
                                }
                                // if(isSplitBoolean){
                                //     this.isSplit = true
                                // }else{
                                //     this.isSplit = false
                                // }
                                console.log("this.isSplit --> ",this.isSplit)
                            });
                    }
                    if (change.type === "removed") {
                        console.log("removed: ", change.doc.data());


                    }
                });

            });

    }

    ngOnInit() { }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }


    dismiss() {
        this.modalController.dismiss();
    }

    async addFriendsToSplit() {
        const modal = await this.modalController.create({
            component: AddFriendsSplitComponent,
            cssClass: 'splitFriendsModal',
            componentProps: {
                'request': this.request,
                'user': this.user,
                'listFriendSplit': this.listFriendSplit != undefined ? this.listFriendSplit : []
            }
        });

        modal.onDidDismiss().then(data => {
            console.log("data", data)
            if (data.data.status == 'dismiss') {
                this.modalController.dismiss();
            }
            if (data.data.data.length > 0) {
                this.listFriendSplit = data.data.data
                console.log(">>> this.listFriendSplit <<<", this.listFriendSplit)

            }
        })
        return await modal.present();
    }

    async paymentMethodsAccount($event) {
        const modal = await this.modalController.create({
            component: PaymentMethodsComponent,
        });
    
        modal.onDidDismiss().then(data => {
            this.card = data.data;
    
            if (this.card == undefined) {
                this.card = false;
                this.cardError = true;
            } else {
                this.cardError = false;
            }
    
        })
        console.log(this.card);
        console.log(this.cardError);
        return await modal.present();
    }

    async paymentMethods() {
        const modal = await this.modalController.create({
            component: PaymentMethodsComponent,
            componentProps: {
                'request': this.requests,
                'user': this.user,
                'payment': this.paymentForm.value
            }
        });

        modal.onDidDismiss().then(data => {
            console.log("data.data.type");
            console.log(data.data.type);

            this.payment["payment_type"] = data.data.type;

            if (this.payment.payment_type == 'balance') {
                this.current_balance = data.data.balance;
            } else {
                this.card = data.data;
                if (this.card == undefined) {
                    this.card = false;
                    this.cardError = true;
                } else {
                    this.cardError = false;
                }
            }

        })

        return await modal.present();
    }

// se quita por el tipo de validaciones para restar lo que han abonado los amigos incluidos en el split
    sendPayment() {

        if (this.card) {
            this.cardError = false;
            this.loader().then(loader => {
                loader.present().then(() => {
                    let payment = this.paymentForm.value;
                    // console.log("payment");
                    // console.log(payment);
                    console.log("this.friend", this.friend)


                    payment.card = this.card;
                    let message = '';
                    if (payment.caption) {
                        message = `: ${payment.caption}`
                    }

                    this.api.getDocument('accounts', this.auth.token).then(data => {
                        // console.log(data)
                        if (data) {
                            // console.log("data['balance']");
                            // console.log(data['balance']);


                            if (data['balance'] >= payment.amount) {
                                if(this.request.amountPaidSplit){
                                    this.payment.amountPaidSplit = this.request.amountPaidSplit
                                }
                                this.api.sendPayment(payment, false, this.friend.$key).then(dataSend => {
                                    // console.log("this.api.sendPayment --> data");
                                    // console.log(dataSend);
                                    // console.log(typeof(data['balance']));
                                    // console.log(data['balance']);
                                    // console.log("payment.amount");
                                    // console.log(typeof(payment.amount));
                                    // console.log(payment.amount);

                                    let newBalance = data['balance'] - payment.amount
                                    this.api.updateDocument('accounts', this.auth.token, { balance: newBalance }).then(data => {
                                        // console.log(data)
                                        this.api.sendPush(`${this.friend.username} ${message}`, `Sent you a pagï of ${payment.amount}`, this.friend.push_keys).then(dataPush => {
                                            console.log(dataPush);
                                            loader.dismiss();
                                            this.presentAlert();
                                        }, err => {
                                            console.log(err);
                                        })
                                    })
                                });
                            } else {
                                loader.dismiss();
                                this.alertController.create({
                                    header: '¡Transaccion Cancelada!',
                                    message: '¡No cuentas con el suficiente saldo pagï para realizar este pago!',
                                    cssClass: 'successAlert',
                                    backdropDismiss: false,
                                    buttons: [
                                        {
                                            text: 'Okay',
                                            cssClass: 'okBtn',
                                            handler: () => {
                                                this.modalController.dismiss('dismiss');
                                            }
                                        }
                                    ]
                                }).then(alert => {
                                    alert.present();
                                })
                            }
                        }
                    })
                })
            });

        } else {
            this.cardError = true;
        }
    }
    // complemento del pago - del split actual
    async sendMySplitPayment(){
        console.log("this.request", this.request)
        let amountPaid = 0
        this.listFriendSplit.forEach(element => {
            console.log("sendMySplitPayment() -> element---> ", element.request.status, element.request.amount)
            // console.log(element.request.status, element.request.amount)
            if(element.request.status == 'paid'){
                amountPaid = element.request.amount + amountPaid
            }
            console.log(amountPaid)
        });
        // let request = this.request 
        this.request.amountPaidSplit = amountPaid
        const modal = await this.modalController.create({
            component: PaymentConfirmationComponent,
            componentProps: {
                'request': this.request,
                'user': this.user,
                'payment': this.paymentForm.value
            }
        });

        modal.onDidDismiss().then(data => {
            if (data.data == 'dismiss') {
                this.modalController.dismiss();
            }
        })
        return await modal.present();
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            header: '¡Éxito!',
            message: '¡Tú pagï fue enviado a tu amig@!',
            cssClass: 'successAlert',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Okay',
                    cssClass: 'okBtn',
                    handler: () => {
                        this.modalController.dismiss('dismiss');
                    }
                }
            ]
        });

        await alert.present();
    }

    async requestPayment(user) {
        console.log("userKey", user)
        const modal = await this.modalController.create({
            component: RequestPaggiComponent,
            componentProps: {
                user: user,
                split: this.request
            }
        });
        return await modal.present();
    }

    // este cancelar funciona diferente: 
    async cancelPayment(user) {
        console.log("cancelPayment");
        console.log(user);

        const alert = await this.alertController.create({
            message: `Esta seguro que deseas eliminar la Solicitud realizada hacia ${user.fullname} (@${user.username}) ?`,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirmando Cancel');
                    }
                }, {
                    text: 'Si, estoy seguro',
                    handler: () => {
                        this.api.updateDocument('requests', user.request.$key, { status: 'anulado' }).then(data => {
                            this.modalController.dismiss();
                        })

                    }
                }
            ]
        });

        await alert.present();
    }


    async borrarPayment(user) {
        console.log("cancelPayment");
        console.log(user);
        // this.listFriendSplit
        console.log("ANTES this.listFriendSplit -->>", this.listFriendSplit)
        let listFriendSplit = this.listFriendSplit.filter(function (value, index, arr) {
            return value.$key != user.$key;
        });
        this.listFriendSplit = listFriendSplit
        console.log("DESPUES this.listFriendSplit -->>", this.listFriendSplit)

    }


}
