import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { NavParams, ModalController, PopoverController, LoadingController, AlertController } from '@ionic/angular';
import { PaymentMethodsComponent } from '../payment-methods/payment-methods.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'app-payment-confirmation',
    templateUrl: './payment-confirmation.component.html',
    styleUrls: ['./payment-confirmation.component.scss'],
})
export class PaymentConfirmationComponent implements OnInit {

    request: any;
    user: any;
    payment: any;
    card: any = false
    cardError: any = false;
    current_balance: any;

    constructor(
        public modalController: ModalController,
        public navParams: NavParams,
        public api: ApiService,
        public popoverController: PopoverController,
        public loadingCtrl: LoadingController,
        public alertController: AlertController,
        public router: Router,
        public auth: AuthService
    ) {
        this.request = navParams.get('request');
        // if(this.request.amountPaidSplit){
        //     this.request.amount = this.request.amountPaidSplit 
        // }
        this.user = navParams.get('user');
        this.payment = navParams.get('payment');
        console.log("datos enviados transferidos utilizando navParams");
        
        console.log("PaymentConfirmationComponent -->> this.request: ",this.request);
        console.log("PaymentConfirmationComponent -->> this.user: ",this.user);
        console.log("PaymentConfirmationComponent -->> this.payment: ",this.payment);
        
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    dismiss() {
        this.modalController.dismiss();
    }

    ngOnInit() { }

    async paymentMethods() {
        const modal = await this.modalController.create({
            component: PaymentMethodsComponent,
        });

        modal.onDidDismiss().then(data => {
            this.payment.payment_type = data.data.type;

            if (this.payment.payment_type == 'balance') {
                this.current_balance = data.data.balance;
            } else {
                this.card = data.data;
                if (this.card == undefined) {
                    this.card = false;
                    this.cardError = true;
                } else {
                    this.cardError = false;
                }
            }

        })

        return await modal.present();
    }

    sendPayment() {
        if (this.payment.payment_type == 'balance') {

            this.loader().then(loader => {
                loader.present().then(() => {
                    console.log("this.request --> ", this.request)
                    console.log("this.payment --> ",this.payment)
                    if(this.request.amountPaidSplit){
                        this.payment.amount = this.request.amount - this.request.amountPaidSplit 
                    }else{
                    this.payment.amount = this.request.amount;
                    }
                    console.log("saldo diferencial : this.payment.amount", this.payment.amount)
                    console.log("this.payment.amount -->> ", this.payment.amount)
                    this.api.getDocument('accounts', this.auth.token).then(account_result => {
                        let balance = account_result['balance'];
                        if (balance >= this.payment.amount) {
                            this.payment.from_balance = balance - this.payment.amount;

                            this.api.sendPayment(this.payment, this.request, this.user.$key).then(data => {
                                loader.dismiss();
                                this.presentAlert();
                            });

                        } else {
                            loader.dismiss();
                            this.alertController.create({
                                header: '¡Ups!',
                                message: '¡No cuentas con el suficiente saldo pagï para realizar este pago!',
                                cssClass: 'successAlert',
                                backdropDismiss: false,
                                buttons: [
                                    {
                                        text: 'Okay',
                                        cssClass: 'okBtn',
                                        handler: () => {
                                            this.modalController.dismiss('dismiss');
                                        }
                                    }
                                ]
                            }).then(alert => {
                                alert.present();
                            })
                        }
                    })
                })
            });

        } else {
            if (this.card) {
                this.cardError = false;
                this.loader().then(loader => {
                    loader.present().then(() => {
                        this.payment.card = this.card;
                        this.payment.amount = this.request.amount;

                        this.api.sendPayment(this.payment, this.request, this.user.$key).then(data => {
                            loader.dismiss();
                            this.presentAlert();
                        });

                    })
                });

            } else {
                this.cardError = true;
            }
        }

    }

    async presentAlert() {
        const alert = await this.alertController.create({
            header: '¡Felicidades!',
            message: '¡tu pago fue enviado correctamente!',
            cssClass: 'successAlert',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Okay',
                    cssClass: 'okBtn',
                    handler: () => {
                        this.modalController.dismiss('dismiss');
                    }
                }
            ]
        });

        await alert.present();
    }

}
