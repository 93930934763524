import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { snapshotChanges } from 'angularfire2/database';

@Component({
    selector: 'app-add-chatbox',
    templateUrl: './add-chatbox.component.html',
    styleUrls: ['./add-chatbox.component.scss'],
})
export class AddChatboxComponent implements OnInit {

    friends: any = [];

    constructor(
        public modalController: ModalController,
        public api: ApiService,
        public auth: AuthService
    ) {
        this.api.getRef(`accounts/${this.auth.token}/friendships`).ref
            .where('status', '==', 'approved')
            .onSnapshot(snapshots => {

                snapshots.docChanges().forEach(change => {
                    if (change.type === "added") {
                        let friendship = change.doc.data();

                        this.api.getRef('accounts').doc(friendship.friend).ref
                            .onSnapshot(snapshot => {
                                let account = snapshot.data();
                                account.$key = snapshot.id;
                                this.friends.push(account);
                            });
                    } else if (change.type == 'removed') {
                        for (var i = 0; i < this.friends.length; i++) {
                            if (this.friends[i].$key === change.doc.id) {
                                this.friends.splice(i, 1);
                            }
                        }
                    } else if (change.type == 'modified') {
                        for (var i = 0; i < this.friends.length; i++) {
                            if (this.friends[i].$key === change.doc.id) {
                                let friendsip = change.doc.data();
                                friendsip.$key = change.doc.id;
                                this.friends[i] = friendsip;
                            }
                        }
                    }
                });

            });
    }

    ngOnInit() { }

    dismiss() {
        this.modalController.dismiss();
    }

    selectFriend(friend) {
        this.modalController.dismiss(
            friend
        )
    }

}
