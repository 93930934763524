import { Component, OnInit } from '@angular/core';
import { MbscNumpadDecimalOptions } from '@mobiscroll/angular';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { MbscFormOptions } from 'src/lib/mobiscroll/js/mobiscroll.angular.min';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, AlertController, NavParams, ModalController } from '@ionic/angular';
import { PaymentMethodsComponent } from '../../payment-methods/payment-methods.component';

@Component({
    selector: 'app-send-paggi',
    templateUrl: './send-paggi.component.html',
    styleUrls: ['./send-paggi.component.scss'],
})
export class SendPaggiComponent implements OnInit {

    numpad: number = 0;
    numpadSettings: MbscNumpadDecimalOptions = {
        theme: 'ios-dark',
        lang: 'en',
        preset: 'decimal',
        min: 0.0,
        max: 1000,
        prefix: '$',
    };

    formSettings: MbscFormOptions = {
        lang: 'en',
        theme: 'ios'
    };

    loaded_user = false;
    friend: any;
    friend_key: any;
    card: any;
    cardError: any = false;
    user: any;
    paymentForm: FormGroup;

    constructor(
        public api: ApiService,
        public navParams: NavParams,
        private auth: AuthService,
        public route: ActivatedRoute,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public alertController: AlertController,
        public modalController: ModalController,
    ) {
        this.friend = navParams.get('user');
        this.friend.push_keys = [];
        this.api.getAllDocuments(`accounts/${this.friend.$key}/push_keys`).then(keys => {
            if (keys) {
                keys.forEach(element => {
                    this.friend.push_keys.push(element.push_key);
                });
            }
        })
        this.friend_key = this.friend.$key;
        this.loaded_user = true;
        this.api.getDocument('accounts', this.auth.token).then(data => {
            this.user = data;
        })

        this.paymentForm = this.formBuilder.group({
            amount: ['', [Validators.required]],
            caption: ['',[Validators.required]],
            privacy: ['public', [Validators.required]],
        });
    }

    ngOnInit() {
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    dismiss() {
        this.modalController.dismiss();
    }

    async paymentMethods(event: any) {
        const modal = await this.modalController.create({
            component: PaymentMethodsComponent,
        });

        modal.onDidDismiss().then(data => {
            this.card = data.data;

            if (this.card == undefined) {
                this.card = false;
                this.cardError = true;
            } else {
                this.cardError = false;
            }

        })

        return await modal.present();
    }

    sendPayment() {

        if (this.card) {
            this.cardError = false;
            this.loader().then(loader => {
                loader.present().then(() => {
                    let payment = this.paymentForm.value;
                    // console.log("payment");
                    // console.log(payment);
                    

                    payment.card = this.card;
                    let message = '';
                    if (payment.caption) {
                        message = `: ${payment.caption}`
                    }

                    this.api.getDocument('accounts', this.auth.token).then(data => {
                        // console.log(data)
                        if(data){
                            // console.log("data['balance']");
                            // console.log(data['balance']);
                            

                        if (data['balance'] >= payment.amount) {
                            this.api.sendPayment(payment, false, this.friend.$key).then(dataSend => {
                                // console.log("this.api.sendPayment --> data");
                                // console.log(dataSend);
                                // console.log(typeof(data['balance']));
                                // console.log(data['balance']);
                                // console.log("payment.amount");
                                // console.log(typeof(payment.amount));
                                // console.log(payment.amount);
                                
                                let newBalance = data['balance'] - payment.amount
                                this.api.updateDocument('accounts', this.auth.token, { balance: newBalance }).then(data => {
                                    // console.log(data)
                                    this.api.sendPush(`${this.friend.username} ${message}`, `Sent you a pagï of ${payment.amount}`, this.friend.push_keys).then(dataPush => {
                                        console.log(dataPush);
                                        loader.dismiss();
                                        this.presentAlert();
                                    }, err => {
                                        console.log(err);
                                    })
                                })
                            });
                        } else {
                            loader.dismiss();
                            this.alertController.create({
                                header: '¡Transaccion Cancelada!',
                                message: '¡No cuentas con el suficiente saldo pagï para realizar este pago!',
                                cssClass: 'successAlert',
                                backdropDismiss: false,
                                buttons: [
                                    {
                                        text: 'Okay',
                                        cssClass: 'okBtn',
                                        handler: () => {
                                            this.modalController.dismiss('dismiss');
                                        }
                                    }
                                ]
                            }).then(alert => {
                                alert.present();
                            })
                        }
                    }
                })
                })
            });

        } else {
            this.cardError = true;
        }
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            header: '¡Éxito!',
            message: '¡Tú pagï fue enviado a tu amig@!',
            cssClass: 'successAlert',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Okay',
                    cssClass: 'okBtn',
                    handler: () => {
                        this.modalController.dismiss('dismiss');
                    }
                }
            ]
        });

        await alert.present();
    }

}
