// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const firebaseConfig = {
  apiKey: "AIzaSyAmoZy0NIum91DAaujfADHsqVsuSIdfmnQ",
  authDomain: "pagg-i.firebaseapp.com",
  databaseURL: "https://pagg-i.firebaseio.com",
  projectId: "pagg-i",
  storageBucket: "pagg-i.appspot.com",
  messagingSenderId: "369705893702"
}

export const algoliaConfig = {
  appId: 'SNYWXQSRQ6',
  apiKey: '567620cc2f11976438494e7856d97bc7'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
