import { Component, OnInit } from '@angular/core';
import algoliasearch from 'algoliasearch';
import { algoliaConfig } from 'src/environments/environment.prod';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';

const client = algoliasearch(algoliaConfig.appId, algoliaConfig.apiKey);
const index = client.initIndex('accounts');

@Component({
    selector: 'app-add-friends-split',
    templateUrl: './add-friends-split.component.html',
    styleUrls: ['./add-friends-split.component.scss'],
})
export class AddFriendsSplitComponent implements OnInit {

    loading: any = true;
    accountsResult: any;
    friends: any = [];
    recents: any = [];
    searching: boolean = false;

    constructor(
        public api: ApiService,
        private auth: AuthService,
        public modalController: ModalController,
        public navParams: NavParams,
        private keyboard: Keyboard
    ) {
        // listFriendSplit
        this.friends = navParams.get('listFriendSplit');
        console.log("this.friends --> navParams ",this.friends)
        this.getRecents();
    }

    ngOnInit() {
    }

    dismiss() {
        this.modalController.dismiss();
    }

    search(e) {
        this.searching = true;
        this.loading = true;

        let query = e.detail.value;

        if (query !== '') {
            let users = [];
            index.search(query)
                .then(({ hits }) => {
                    hits.forEach(element => {
                        console.log(element);

                        // element.$key = element.objectID;
                        // if (element.objectID !== this.auth.token) {
                        //     users.push(element)
                        // }

                        // let find = this.friends.find(elemento => elemento == element.$key);

                        // if (find) {
                        //     element.checked = true;
                        // }
                    });
                    this.accountsResult = users;
                    this.loading = false;
                });
        } else {
            this.accountsResult = [];
            this.loading = false;
            this.searching = false;
        }
    }

    userToSplit(e, user) {
        console.log("accountsResult", this.accountsResult, "e", e, "user", user, "this.friends", this.friends)
        user.checked = e.detail.checked;
        console.log(user);

        if (e.detail.checked) {

            let find = this.friends.find(element => element == user.$key);
            if (!find) {
                this.friends.push(user)
            }

        } else {

            let friends = this.friends.filter(function(value, index, arr){ 
                return value.$key != user.$key;
            });
            this.friends = friends
            console.log("luego de eliminar check" ,  this.friends)

        }
    }

    onScroll() {
        this.keyboard.hide();
    }

    getRecents() {
        this.searching = false;
        this.api.getRef(`accounts/${this.auth.token}/recent_searches`).ref
            .orderBy('date')
            .limit(25)
            .onSnapshot(snapshots => {
                snapshots.docChanges().forEach(change => {
                    if (change.type == 'added') {
                        this.api.getDocument('accounts', change.doc.data().account).then(user => {
                            let find = this.friends.find(elemento => elemento.$key == user['$key']);
                            if (find) {
                                user['checked'] = true;
                            }
                            this.recents.unshift(user);
                        })

                    } else if (change.type == 'removed') {

                    }
                });
                this.loading = false;
            })
    }

    addToRecent(account) {
        let exist = this.searchInArray(account);
        if (!exist) {
            this.api.addDocument(`accounts/${this.auth.token}/recent_searches`, {
                account: account,
                date: new Date()
            });
        }
    }

    searchInArray(key) {
        for (var i = 0; i < this.recents.length; i++) {
            if (this.recents[i].$key === key) {
                return this.recents[i];
            }
        }
    }

    continue() {
        this.modalController.dismiss( {data: this.friends, status:"dismiss"});
    }
}
