export const environment = {
  production: true
};
export const firebaseConfig = {
  apiKey: "AIzaSyAmoZy0NIum91DAaujfADHsqVsuSIdfmnQ",
  authDomain: "pagg-i.firebaseapp.com",
  databaseURL: "https://pagg-i.firebaseio.com",
  projectId: "pagg-i",
  storageBucket: "pagg-i.appspot.com",
  messagingSenderId: "369705893702"
}

export const algoliaConfig = {
  appId: 'SNYWXQSRQ6',
  apiKey: '567620cc2f11976438494e7856d97bc7'
}
