import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import * as moment from 'moment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {

    public post: any;
    public user: any;
    commentForm: FormGroup;
    comments: any = [];

    constructor(
        public modalController: ModalController,
        public navParams: NavParams,
        public auth: AuthService,
        public api: ApiService,
        public formBuilder: FormBuilder,
    ) {
        this.post = navParams.get('post');

        this.post.moment = this.post.date.toDate();
        this.post.moment = moment(this.post.moment).fromNow();

        this.api.getDocument('accounts', this.auth.token).then(data => {
            this.user = data;
        });

        this.commentForm = this.formBuilder.group({
            date: [new Date(), [Validators.required]],
            comment: ['', [Validators.required]],
            user: [this.auth.token, [Validators.required]]
        });

        if (this.post.activity.type == 'request') {

            this.api.getRef(`requests/${this.post.$key}/comments`).ref
                .limit(10)
                .orderBy('date', 'desc')
                .onSnapshot(snapshots => {
                    snapshots.docChanges().forEach(change => {
                        if (change.type === "added") {
                            let comment = change.doc.data();
                            comment.$key = change.doc.id;
                            comment.moment = comment.date.toDate();
                            comment.moment = moment(comment.moment).fromNow();

                            this.api.getDocument('accounts', comment.user).then(userData => {
                                comment.user = userData;
                                this.comments.push(comment);
                            })
                        }
                    })
                    console.log(this.comments);

                })


            this.api.getRef(`requests/${this.post.$key}/likes`).ref
                .where('user', '==', this.auth.token)
                .onSnapshot(data => {
                    if (data.size) {
                        this.post['liked'] = 'danger';
                        data.forEach(element => {
                            this.post['like_key'] = element.id;
                        });
                    } else {
                        this.post['liked'] = 'medium';
                        delete this.post['like_key'];
                    }
                })

            this.api.getRef(`requests/${this.post.$key}/comments`).ref
                .onSnapshot(data => {
                    this.post['comments'] = data.size;
                })

            this.api.getRef(`requests/${this.post.$key}/likes`).ref
                .onSnapshot(data => {
                    this.post['likes'] = data.size;
                })

        }
        else if (this.post.activity.type == 'payment') {
            this.api.getRef(`payments/${this.post.$key}/comments`).ref
                .limit(10)
                .orderBy('date', 'desc')
                .onSnapshot(snapshots => {
                    snapshots.docChanges().forEach(change => {
                        if (change.type === "added") {
                            let comment = change.doc.data();
                            comment.$key = change.doc.id;
                            comment.moment = comment.date.toDate();
                            comment.moment = moment(comment.moment).fromNow();

                            this.api.getDocument('accounts', comment.user).then(userData => {
                                comment.user = userData;
                                this.comments.push(comment);
                            })
                        }
                    })
                })

            this.api.getRef(`payments/${this.post.$key}/likes`).ref
                .where('user', '==', this.auth.token)
                .onSnapshot(data => {
                    if (data.size) {
                        this.post['liked'] = 'danger';
                        data.forEach(element => {
                            this.post['like_key'] = element.id;
                        });
                    } else {
                        this.post['liked'] = 'medium';
                        delete this.post['like_key'];
                    }
                })

            this.api.getRef(`payments/${this.post.$key}/comments`).ref
                .onSnapshot(data => {
                    this.post['comments'] = data.size;
                })

            this.api.getRef(`payments/${this.post.$key}/likes`).ref
                .onSnapshot(data => {
                    this.post['likes'] = data.size;
                })

        }



    }

    dismiss() {
        this.modalController.dismiss();
    }

    ngOnInit() { }

    addComment() {
        this.commentForm.controls['date'].setValue(new Date());

        if (this.post.activity.type == 'request') {
            this.api.addDocument(`requests/${this.post.$key}/comments`, this.commentForm.value).then(data => {
                this.commentForm.controls['comment'].setValue('');
            });
        }
        else if (this.post.activity.type == 'payment') {
            this.api.addDocument(`payments/${this.post.$key}/comments`, this.commentForm.value).then(data => {
                this.commentForm.controls['comment'].setValue('');
            });
        }

    }

    like(post) {
        if (!post.loading_like) {
            post.loading_like = true;
            if (post.liked == 'danger') {
                post.liked = 'medium';
                this.api.deleteDocument(`accounts/${post.to_user.$key}/notifications`, post.like_key)
                this.api.deleteDocument(`accounts/${post.from_user.$key}/notifications`, post.like_key)
                if (post.activity.type == 'payment') {
                    this.api.deleteDocument(`payments/${post.$key}/likes`, post.like_key).then(data => {
                        post.liked = 'medium';
                        post.loading_like = false;
                    })
                } else if (post.activity.type == 'request') {
                    this.api.deleteDocument(`requests/${post.$key}/likes`, post.like_key).then(data => {
                        post.liked = 'medium';
                        post.loading_like = false;
                    })
                }
            } else {
                post.liked = 'danger';
                if (post.activity.type == 'payment') {
                    this.api.addDocument(`payments/${post.$key}/likes`, { user: this.auth.token, date: new Date() }).then(data => {
                        post.liked = 'danger';
                        post.like_key = data.id;
                        post.loading_like = false;

                        this.api.setDocument(`accounts/${post.to_user.$key}/notifications`, post.like_key, {
                            type: 'like',
                            from: this.auth.token,
                            date: new Date(),
                            new: true
                        })

                        this.api.setDocument(`accounts/${post.from_user.$key}/notifications`, post.like_key, {
                            type: 'like',
                            from: this.auth.token,
                            date: new Date(),
                            new: true
                        })
                    })
                } else if (post.activity.type == 'request') {
                    this.api.addDocument(`requests/${post.$key}/likes`, { user: this.auth.token, date: new Date() }).then(data => {
                        post.liked = 'danger';
                        post.like_key = data.id;
                        post.loading_like = false;

                        this.api.setDocument(`accounts/${post.to_user.$key}/notifications`, post.like_key, {
                            type: 'like',
                            from: this.auth.token,
                            date: new Date(),
                            new: true
                        })
                        this.api.setDocument(`accounts/${post.from_user.$key}/notifications`, post.like_key, {
                            type: 'like',
                            from: this.auth.token,
                            date: new Date(),
                            new: true
                        })
                    })
                }
            }
        }
    }

    async goToComments(post) {
        const modal = await this.modalController.create({
            component: CommentsComponent,
            componentProps: {
                post: post
            },
            animated: true,
        });
        return await modal.present();
    }
    
}
