<ion-header>
    <ion-toolbar color="light">
        <ion-buttons slot="start">
            <ion-button fill="clear" color="dark" (click)="dismiss()">cancel</ion-button>
        </ion-buttons>
        <ion-title>Split ss</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" color="fondo">
    <ion-grid class="height-100">
        <ion-row class="height-100" class="ion-justify-content-center ion-align-items-center">
            <ion-col size="12">

                <ion-grid>
                    <ion-row class="ion-text-center">
                        <ion-col size="4" offset="4">
                            <ion-avatar>
                                <img src="assets/imageholder.svg" *ngIf="!user?.profilePicture">
                                <img src="{{user?.profilePicture.downloadURL}}" *ngIf="user?.profilePicture">
                            </ion-avatar>
                        </ion-col>
                    </ion-row>
                </ion-grid>

                <ion-row class="margin-bottom centerItems">
                    <ion-col size="12" class="number_pad ion-text-center ion-no-padding">
                        <ion-row>
                            <ion-col size="12">
                                <h4>
                                    <ion-label color="text-primary">@{{user?.username}}</ion-label>
                                </h4>
                                <ion-label *ngIf="request?.from_user?.estado" color="text-color">
                                    {{request?.from_user?.estado}}<br></ion-label>
                                <ion-label *ngIf="!request?.from_user?.estado" color="text-color">Sin estado</ion-label>
                                <br>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>



                <ion-row class="margin-bottom centerItems">
                    <ion-col size="12" class="number_pad ion-text-center ion-no-padding">
                        <ion-row>
                            <ion-col size="12">
                                <ion-label color="text-primary" class="ion-text-right" style="font-size: 3em">
                                    ï {{request?.amount | number}}
                                </ion-label>
                                <br><br>
                                <ion-label color="text-primary" style="font-size: 20px;"> Solicitud de PAGï</ion-label>
                                <br><br>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>

                <form [formGroup]="paymentForm" novalidate>

                    <ion-text class="justify-content-left" color="text-primary">Descripcion</ion-text>
                    <ion-input style="border-bottom: #FFDB33; border-bottom-style: solid; "
                        formControlName="description" name="caption" placeholder="Descripcion..."
                        color="text-secondary">
                    </ion-input>

                    <br>

                    <ion-text class="justify-content-left" color="text-primary">Método de pago</ion-text><br>
                    <ion-button color="text-primary" color="text-primary" fill="clear" expand="block" size="medium"
                        (click)="paymentMethodsAccount($event)">
                        <ion-label *ngIf="card">
                            <ion-icon slot="start" src="./assets/icons/{{card.cardType}}.svg"></ion-icon>
                            • • • • {{card.cardMask}}
                        </ion-label>
                        <ion-label *ngIf="!card">
                            <ion-text color="text-secondary">
                                Select a payment method
                            </ion-text>
                        </ion-label>
                        <ion-icon slot="end" name="caret-down-outline"></ion-icon>
                    </ion-button>
                    <ion-text color="danger" *ngIf="cardError">
                        You must select a payment method
                    </ion-text>
                    <hr style="border-bottom: #FFDB33; border-bottom-style: solid; ">
                    <br>

                    <ion-label color="text-primary">Privacidad</ion-label><br>

                    <ion-select formControlName="privacy" placeholder="Select One" interface="popover">

                        <ion-select-option color="text-primary" value="public">
                            Público
                        </ion-select-option>

                        <ion-select-option color="text-primary" value="private">
                            Privado
                        </ion-select-option>
                    </ion-select>
                    <hr style="border-bottom: #FFDB33; border-bottom-style: solid; ">
                    <br>
                    <br>

                </form>

                <ion-grid>
                    <ion-row>
                        <ion-col size="7">
                            <ion-label color="text-primary">SPLIT esta solictitud con: </ion-label>
                        </ion-col>
                        <ion-col size="3">
                            <ion-button color="primary" shape="round" size="small" (click)="addFriendsToSplit()">
                                Add friends to split
                            </ion-button>
                        </ion-col>
                    </ion-row>
                    <!-- construyendo la lista de request de Split de la cuenta actual -->
                    <ion-row>
                        <ion-col size="12">

                            <ion-list no-padding *ngIf="listFriendSplit" lines="none">
                                <ion-item-sliding *ngFor="let user of listFriendSplit" lines="none">

                                    <ion-item color="fondo" lines="none">
                                        <!-- contenido a iterar (lista de ususarios con split actual) -->
                                        <ion-label text-wrap>
                                            @{{user?.username}}
                                        </ion-label>

                                        <ion-label *ngIf="user?.request?.status" text-wrap>
                                            {{user?.request?.amount | currency:' ï'}}
                                        </ion-label>

                                        <ion-label *ngIf="user?.request?.status  != 'pending'" text-wrap>
                                            {{user?.request?.status}}
                                        </ion-label>

                                        <div color="fondo">

                                            <ion-button *ngIf="!user?.request?.status" class="buttonsPagi"
                                                color="text-primary" size="small" shape="round"
                                                (click)="requestPayment(user)">
                                                Request PAGï
                                            </ion-button>

                                            <ion-button *ngIf="user?.request?.status == 'pending'" class="buttonsPagi"
                                                color="text-primary" size="small" shape="round"
                                                (click)="cancelPayment(user)">
                                                Cancelar
                                            </ion-button>

                                        </div>
                                    </ion-item>

                                    <ion-item-options side="end" *ngIf="!user?.request?.status">
                                        <ion-item-option color="danger" expandable (click)="borrarPayment(user)">
                                            Borrar
                                        </ion-item-option>
                                    </ion-item-options>

                                </ion-item-sliding>

                            </ion-list>

                        </ion-col>

                    </ion-row>

                </ion-grid>

                <ion-grid>
                    <ion-row>
                        <ion-col size="8" offset="2">
                            <ion-button color="text-primary" shape="round" size="small" expand="full"
                                (click)="sendMySplitPayment()" [disabled]="isSplit">
                                <ion-icon slot="start" name="send-sharp"></ion-icon>
                                Split
                            </ion-button>

                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>