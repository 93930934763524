import { Component, OnInit } from '@angular/core';
import { MbscNumpadDecimalOptions } from '@mobiscroll/angular';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { MbscFormOptions } from 'src/lib/mobiscroll/js/mobiscroll.angular.min';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, AlertController, NavParams, ModalController } from '@ionic/angular';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
    selector: 'app-request-paggi',
    templateUrl: './request-paggi.component.html',
    styleUrls: ['./request-paggi.component.scss'],
})
export class RequestPaggiComponent implements OnInit {

    numpad: number = 0;
    numpadSettings: MbscNumpadDecimalOptions = {
        theme: 'ios-dark',
        lang: 'en',
        preset: 'decimal',
        min: 0.0,
        max: 1000,
        prefix: '$',
    };

    formSettings: MbscFormOptions = {
        lang: 'en',
        theme: 'ios'
    };


    loaded_user = false;
    friend: any;
    friend_key: any;
    user: any;

    requestForm: FormGroup;
    // para menejo del split que nace con el request del comercio
    split: any;

    constructor(
        public api: ApiService,
        public navParams: NavParams,
        private auth: AuthService,
        public route: ActivatedRoute,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public alertController: AlertController,
        public modalController: ModalController
    ) {
        this.friend = navParams.get('user');
        this.split = navParams.get('split');
        // el split que envio el comercio al amigo
        console.log("this.split",this.split)
        this.friend.push_keys = [];
        this.api.getAllDocuments(`accounts/${this.friend.$key}/push_keys`).then(keys => {
            if (keys) {
                keys.forEach(element => {
                    this.friend.push_keys.push(element.push_key);
                });
            }
        })
        this.friend_key = this.friend.$key;
        this.loaded_user = true;

        this.api.getDocument('accounts', this.auth.token).then(data => {
            this.user = data;
            console.log("typeof(this.user) -> ",typeof(this.user), this.user, this.user.type)
        })

        this.requestForm = this.formBuilder.group({
            amount: ['', [Validators.required]],
            comment: ['',[Validators.required]],
            privacy: ['public', [Validators.required]],
        });
    }

    ngOnInit() {

    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    dismiss() {
        this.modalController.dismiss();
    }

    sendRequest() {
        this.loader().then(loader => {
            loader.present().then(() => {
                let request = this.requestForm.value;
                request.from_account = this.auth.token;
                request.to_account = this.friend_key;
                request.status = 'pending';
                request.shown = false;
                request.date = new Date();
                request.type = 'p2p';
                request.account_type = this.user.type != undefined ? this.user.type : 'person'
                if(this.split){
                    request.split = this.split
                }
                let message = '';
                if (request.comment) {
                    message = `: ${request.comment}`
                }

                this.api.sendRequest(request).then(data => { 

                    this.api.sendPush(`${this.friend.username} ${message}`, `Sent you a request you a pagï for ${request.amount}`, this.friend.push_keys).then(data => {
                        console.log(data);
                    }, err => {
                        console.log(err);
                    })

                    loader.dismiss();
                    // this.modalController.dismiss({ type: 'sendRequest', 'to_account': this.friend_key });
                    this.presentAlert();
                });

            })
        })
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            header: '¡Éxito!',
            message: '¡La petición de pagï a tu amig@ fue enviada!',
            cssClass: 'successAlert',
            buttons: [
                {
                    text: 'Okay',
                    cssClass: 'okBtn',
                    handler: () => {
                        this.modalController.dismiss();
                    }
                }
            ]
        });

        await alert.present();
    }

}
