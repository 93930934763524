import { Component, OnInit } from '@angular/core';
import { MbscNumpadDecimalOptions } from '@mobiscroll/angular';
import { MbscFormOptions } from 'src/lib/mobiscroll/js/mobiscroll.angular.min';
import { LoadingController, AlertController, NavParams, ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ApiService } from 'src/app/services/api/api.service';
import { PaymentMethodsComponent } from '../../payment-methods/payment-methods.component';

@Component({
  selector: 'app-charge-paggi',
  templateUrl: './charge-paggi.component.html',
  styleUrls: ['./charge-paggi.component.scss'],
})
export class ChargePaggiComponent implements OnInit {



  numpad: number = 0;
  numpadSettings: MbscNumpadDecimalOptions = {
    theme: 'ios-dark',
    lang: 'en',
    preset: 'decimal',
    min: 0.0,
    max: 1000,
    prefix: '$',
  };

  formSettings: MbscFormOptions = {
    lang: 'en',
    theme: 'ios'
  };

  user: any;
  loaded_user = false;
  chargeForm: FormGroup;
  card: any;
  cardError: any = false;

  constructor(
    public modalController: ModalController,
    public api: ApiService,
    private auth: AuthService,
    public formBuilder: FormBuilder,
    public loadingCtrl: LoadingController,
    public alertController: AlertController,
  ) {

    //llamado de datos del usuario
    this.api.getDocument('accounts', this.auth.token).then(data => {
      this.user = data;
    })

    this.friend= {
      username: 'Rebeca',
      fullname: 'Perez',
      estado: true,
    }

    this.chargeForm = this.formBuilder.group({
      amount: ['', [Validators.required]],
      description: [''],
    });

  }

  ngOnInit() { }

  friend: any;

  dismiss() {
    this.modalController.dismiss();
  }

  // Modificacion por actualización
  async paymentMethods(event: any) {
    const modal = await this.modalController.create({
      component: PaymentMethodsComponent,
    });

    modal.onDidDismiss().then(data => {
      this.card = data.data;

      if (this.card == undefined) {
        this.card = false;
        this.cardError = true;
      } else {
        this.cardError = false;
      }

    })
    console.log(this.card);
    console.log(this.cardError);
    return await modal.present();
  }

  async loader() {
    return await this.loadingCtrl.create({
      spinner: 'bubbles',
      animated: true,
      mode: 'ios',
      translucent: true,
      cssClass: 'custom-class custom-loading',
      // message: 'Please wait...',
    });
  }

  chargePayment() {

    if (this.card) {
      this.cardError = false;
      this.loader().then(loader => {
        loader.present().then(() => {
          console.log("porcesar pago");
          console.log(this.chargeForm.value);

          setTimeout(() => {
            loader.dismiss();
          }, 1000);

        })
      });

    } else {
      this.cardError = true;
    }
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: '¡Éxito!',
      message: '¡Tú pagï fue enviado a tu amig@!',
      cssClass: 'successAlert',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Okay',
          cssClass: 'okBtn',
          handler: () => {
            this.modalController.dismiss('dismiss');
          }
        }
      ]
    });

    await alert.present();
  }

  sendPayment(){}
}
