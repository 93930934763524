<ion-header>
    <ion-toolbar color="light">

        <ion-buttons slot="start">
            <ion-button color="dark" (click)="dismiss()">
                <ion-icon slot="icon-only" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>

        <ion-title>Payment Methods</ion-title>

        <ion-buttons slot="end">
            <ion-button color="dark" (click)="addPaymentMethod()">
                <ion-icon slot="icon-only" name="add-outline"></ion-icon>
            </ion-button>
        </ion-buttons>

    </ion-toolbar>
</ion-header>

<ion-content color="fondo">
    <ion-list>

        <ion-item  color="fondo" lines="full" class="cards-item" *ngIf="account?.balance" (click)="paggiBalance()">
            <ion-col size="8" color="fondo">
                <ion-text color="text-color">
                    <h6>Mi saldo PAGï disponible</h6>
                </ion-text>
            </ion-col>
            <ion-col size="3" offset="1" color="fondo">
                <ion-text color="text-primary">
                    <p>
                        {{account?.balance | currency:'ï '}}
                    </p>
                </ion-text>
            </ion-col>
        </ion-item>

        <ion-item color="fondo" lines="full" class="cards-item" *ngFor="let card of cards" (click)="selectCard(card)">
            <ion-col size="8" color="fondo">
                <ion-text color="text-color">
                    <h6>Tarjeta {{card.type}}</h6>
                </ion-text>
            </ion-col>
            <ion-col size="3" offset="1" color="fondo">
                <ion-text color="text-primary">
                    <p>
                        • {{card.cardMask}}
                    </p>
                </ion-text>
            </ion-col>
        </ion-item>

        <ion-item color="fondo" lines="full" (click)="addPaymentMethod()">
            <ion-label color="text-color">
                Agregar Tarjeta
            </ion-label>
        </ion-item>

        <ion-item style="display: flex;" color="fondo" lines="full" class="cards-item" (click)="addPaymentMethod()">
            <ion-col size="4">
                <img class="imgCard" src="../../../assets/icons/visa.svg" alt="">
            </ion-col>
            <ion-col size="4">
                <img class="imgCard" src="../../../assets/icons/mastercard.svg" alt="">
            </ion-col>
            <ion-col size="4">
                <img class="imgCard" src="../../../assets/icons/amex.svg" alt="">
            </ion-col>
        </ion-item>

    </ion-list>
</ion-content>