import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoadingController,ModalController} from '@ionic/angular';

@Component({
  selector: 'app-complete-info',
  templateUrl: './complete-info.component.html',
  styleUrls: ['./complete-info.component.scss'],
})
export class CompleteInfoComponent implements OnInit {

  account: any;
  completeInfoForm: FormGroup;
  
  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    private auth: AuthService,
    public loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
  ) {
    this.completeInfoForm = this.formBuilder.group({
      dui: ['', [Validators.required,Validators.minLength(10)]],
      phone: ['', [Validators.required,Validators.minLength(9)]],
    });

    this.api.getRef('accounts').doc(this.auth.token).ref
            .onSnapshot(snapshot => {
                let account = snapshot.data();
                account.key = snapshot.id;
                this.account = account;
                if (account.phone) {
                    this.completeInfoForm.controls['phone'].setValue(account.phone);
                }
                if (account.dui) {
                    this.completeInfoForm.controls['dui'].setValue(account.dui);
                }
                
            });
   }

   async loader() {
    return await this.loadingCtrl.create({
        spinner: 'bubbles',
        animated: true,
        mode: 'ios',
        translucent: true,
        cssClass: 'custom-class custom-loading',
        // message: 'Please wait...',
    });
}

   saveInfo() {
    this.loader().then(loader => {
        loader.present().then(() => {
            console.log( this.completeInfoForm.value ); 
            this.api.updateDocument('accounts', this.auth.token, this.completeInfoForm.value).then(data => {
                loader.dismiss();
                this.dismiss();
            });

        })
    })
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {}

}
