
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalController, LoadingController } from '@ionic/angular';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

    recover = '';

    constructor(
        public route: Router,
        public auth: AuthService,
        public modalController: ModalController,
        public loadingCtrl: LoadingController
    ) { 

    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    ngOnInit() { }
    goToRecovery() {
        Swal.fire('¡Éxito !', '¡Verifica tu correo!', 'success');
        this.route.navigate(['login']);
        this.modalController.dismiss();
    }
    
    recoverAccount() {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.auth.resetPassword(this.recover).then(data => {
                    Swal.fire('Recupera tu contraseña!', 'Te enviamos un link de recuperación de contraseña a tu correo electrónico', 'success');
                    this.modalController.dismiss();
                    loader.dismiss();
                }, err=>{
                    Swal.fire('¡Recupera tu contraseña!', 'Te enviamos un link de recuperación de contraseña a tu correo electrónico', 'success');
                    loader.dismiss();
                })
            })
        })
    }
    closeModal() {
        this.modalController.dismiss();
    }
}
