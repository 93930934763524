import { Injectable } from '@angular/core'
import { Router, CanActivate } from '@angular/router'
import { AuthService } from '../../services/auth/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private auth: AuthService
    ) {
    }

    canActivate(route) {
        
        if (this.auth.token) {
            return true;
        } else {
            // this.router.navigate(['/login'])
            this.router.navigate(['/LoginInitPage'])
            return false
        }
        return true;
        
        // if (this.auth.token) {
        //     return true;
        // } else {
        //     this.router.navigate(['/login'])
        //     return false
        // }
    }
}