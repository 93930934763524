import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-birth-date',
  templateUrl: './modal-birth-date.component.html',
  styleUrls: ['./modal-birth-date.component.scss'],
})
export class ModalBirthDateComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
