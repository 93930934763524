<ion-header>
  <ion-toolbar color="fondo">
      <ion-buttons slot="start">
          <ion-button fill="clear" color="text-color" (click)="dismiss()">cancel</ion-button>
      </ion-buttons>
      <ion-title color="text-primary">Charge pagï</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content >

  <form  [formGroup]="chargeForm" novalidate>
      <ion-grid >
          <ion-row >
              <ion-col size="12">
                  <ion-grid>
                      <ion-row>
                          <ion-col size="4" offset="4">
                              <ion-avatar>
                                  <img src="{{user?.profilePicture.downloadURL}}" *ngIf="user?.profilePicture">
                                  <img src="assets/imageholder.svg" *ngIf="!user?.profilePicture">
                              </ion-avatar>
                          </ion-col>
                      </ion-row>
                  </ion-grid>

                  <ion-grid>
                    <ion-row>
                        <ion-col size="4" offset="4" class="ion-text-center">
                          <h4 class="saldo">{{friend?.username}}</h4>
                          <ion-label color="text-primary">{{friend?.fullname}}</ion-label><br>
                          <ion-label *ngIf="friend?.estado" color="text-color"> {{friend?.estado}}</ion-label>
                          <ion-label *ngIf="!friend?.estado" color="text-color"> Sin Estado</ion-label>
                          <br>
                        </ion-col>
                    </ion-row>
                </ion-grid>

                  

                  <ion-row class="margin-bottom centerItems">
                      <ion-col size="8" offset="2" class="number_pad ion-text-center ion-no-padding">
                          <ion-row>
                              <ion-col size="2">
                                  <ion-label color="text-primary" class="ion-text-right" style="font-size: 3em">
                                      ï
                                  </ion-label>
                              </ion-col>
                              <ion-col size="10" class="ion-text-left">
                                  <ion-input color="text-primary" class="input-number" mbsc-numpad-decimal [mbsc-options]="numpadSettings"
                                      formControlName="amount"></ion-input>
                              </ion-col>
                          </ion-row>
                      </ion-col>
                  </ion-row>

                  <ion-text class="justify-content-left" color="text-primary">Agrega una descripcion</ion-text>  
                      <ion-input style="border-bottom: #FFDB33; border-bottom-style: solid; " name="description" placeholder="Descripcion" color="text-secondary"
                                  formControlName="description">
                      </ion-input>
                      <br>

                  <ion-text class="justify-content-left" color="text-primary">Método de pago</ion-text><br><br>
                  <ion-button color="text-primary" color="text-primary" fill="outline" expand="block" size="medium"
                      (click)="paymentMethods($event)">
                      <ion-label *ngIf="card">
                          <ion-icon slot="start" src="./assets/icons/{{card.cardType}}.svg"></ion-icon>
                          • • • • {{card.cardMask}}
                      </ion-label>
                      <ion-label *ngIf="!card">
                          <ion-text color="text-secondary">
                              Select a payment method
                          </ion-text>
                      </ion-label>
                      <ion-icon slot="end" name="caret-down-outline"></ion-icon>
                  </ion-button>
                  <br>
                  <ion-text color="danger" *ngIf="cardError">
                      You must select a payment method
                  </ion-text>
                  <hr style="border-bottom: #FFDB33; border-bottom-style: solid; "> 
                    
              </ion-col>
              <ion-col size="12" class="ion-text-center">
                <ion-button style="border-radius: 50%;" color="text-primary"  (click)="chargePayment()">
                  Recarga PAGïs
              </ion-button>
              </ion-col>
          </ion-row>
      </ion-grid>
  </form>

</ion-content>