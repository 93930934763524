import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {

  constructor(
    public modalController: ModalController,
    public api: ApiService,
    ) { }

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss();
  }

}
