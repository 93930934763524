import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore } from 'angularfire2/firestore';
import { AuthService } from '../auth/auth.service';
import * as firebase from 'firebase/app';
import 'firebase/storage'
import { AngularFireAuth } from 'angularfire2/auth';

@Injectable({
    providedIn: 'root'
})
export class RealtimeApiService {

    constructor(
        private afAuth: AngularFireAuth,
        private afdb: AngularFireDatabase,
        private auth: AuthService
    ) { }

    getConversationId(user_key) {
        return this.afdb.object('/accounts/' + this.auth.token + '/conversations/' + user_key);
    }

    // Get messages of the conversation given the Id.
    getConversationMessages(conversationId) {
        return this.afdb.object('/conversations/' + conversationId + '/messages');
    }

    // Get conversation given the conversationId.
    getConversation(conversationId) {
        return this.afdb.object('/conversations/' + conversationId);
    }

    getConversations() {
        return this.afdb.list('/accounts/' + this.auth.token + '/conversations');
    }

    // Get user by their userId
    getUser(userId) {
        return this.afdb.object('/accounts/' + userId);
    }

    addMessage(conversationId, message) {

        this.getConversationMessages(conversationId).query.once('value', snapshot => {

            let chat_key = firebase.database().ref().child(`/conversations/${conversationId}/messages`).push().key;

            let updates = {};

            updates[`/conversations/${conversationId}/messages/${snapshot.numChildren()}`] = message;

            firebase.database().ref().update(updates);
        })


    }




}
