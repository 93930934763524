<ion-header>
    <ion-toolbar color="fondo">
        <ion-buttons slot="start">
            <ion-button fill="clear" color="text-color" (click)="dismiss()">cancel</ion-button>
        </ion-buttons>
        <ion-title color="text-primary">Solicitar Pagï</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center" color="fondo">


    <ion-grid class="height-100">
        <ion-row class="height-100 ion-justify-content-center ion-align-items-center">
            <ion-col size="12">
                <form [formGroup]="requestForm" novalidate>
                    <div class="margin-bottom">
                        <ion-row *ngIf="!loaded_user">
                            <ion-col size="8" offset="2">
                                <ion-item lines="none">

                                    <ion-avatar slot="start">
                                        <ion-skeleton-text animated></ion-skeleton-text>
                                    </ion-avatar>

                                    <ion-label>
                                        <h3>
                                            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                                        </h3>
                                        <p>
                                            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                                        </p>
                                    </ion-label>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row *ngIf="loaded_user">

                            <ion-col size="4" offset="4">
                                <ion-avatar>
                                    <ion-avatar>
                                        <img src="{{friend?.profilePicture.downloadURL}}"
                                            *ngIf="friend?.profilePicture">
                                        <img src="assets/imageholder.svg" *ngIf="!friend?.profilePicture">
                                    </ion-avatar>
                                </ion-avatar>
                            </ion-col>

                            <ion-col size="8" offset="2">
                                <ion-item lines="none" class="ion-text-center">
                                    <ion-label class="ion-text-center">
                                        <ion-label color="text-primary">{{friend?.username}}</ion-label>
                                        <ion-label *ngIf="friend?.estado" color="text-color"> {{friend?.estado}}</ion-label>
                                        <ion-label *ngIf="!friend?.estado" color="text-color"> Sin Estado</ion-label>
                                    </ion-label>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </div>


                    <ion-row class="margin-bottom centerItems">
                        <ion-col size="8" offset="2" class="number_pad ion-text-center ion-no-padding">
                            <ion-row>
                                <ion-col size="2">
                                    <ion-label color="text-primary" class="ion-text-right" style="font-size: 3em">
                                        ï
                                    </ion-label>
                                </ion-col>
                                <ion-col size="10" class="ion-text-left">
                                    <ion-input color="text-primary" class="input-number" mbsc-numpad-decimal [mbsc-options]="numpadSettings"
                                        formControlName="amount"></ion-input>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>

                    <ion-row class="margin-bottom centerItems">
                        <ion-col size="12" class="number_pad ion-text-center ion-no-padding">
                            <ion-text class="justify-content-left" color="text-primary">Agrega una descripcion</ion-text>  
                            <ion-input style="border-bottom: #FFDB33; border-bottom-style: solid; "placeholder="Agregar descripcion"  name="comment"  class="ion-text-center textarea-request-popup" color="text-secondary"
                                        formControlName="comment">
                            </ion-input>
                        </ion-col>
                    </ion-row>

                    <ion-text class="justify-content-left" color="text-primary">Privacidad de la actividad</ion-text>  
 
                        <ion-select placeholder="Select One" interface="popover" formControlName="privacy">
                            <ion-select-option color="text-primary" value="public">
                                Public
                            </ion-select-option>
                            <ion-select-option color="text-primary" value="private">
                                Private
                            </ion-select-option>
                        </ion-select>
                        <br>

                    <ion-button color="text-primary" [disabled]="requestForm.invalid" (click)="sendRequest()">
                       Request PAGï
                    </ion-button>
                </form>
            </ion-col>
        </ion-row>
    </ion-grid>

</ion-content>