<ion-header>
    <ion-toolbar color="fondo">
        <ion-buttons slot="start">
            <ion-button fill="clear" color="dark" (click)="dismiss()">cancel</ion-button>
        </ion-buttons>
        <ion-title>Envio de pagï</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center ion-padding" color="fondo">

    <ion-grid class="height-100">
        <ion-row class="height-100 ion-justify-content-center ion-align-items-center">
            <ion-col size="12">
                <ion-label color="text-primary">
                    Confirma tu transacción de
                </ion-label><br>

                <ion-text class="text-primary">
                    <ion-label color="text-primary" class="sign">$</ion-label>
                    <ion-label color="text-primary" class="amount">{{request?.amount - request?.amountPaidSplit | number}}</ion-label>
                </ion-text><br>
                <ion-label color="text-primary">
                   para
                </ion-label><br>
                <ion-grid>
                    <ion-row>
                        <ion-col size="4" offset="4">
                            <ion-avatar>
                                <img src="{{user?.profilePicture.downloadURL}}" *ngIf="user?.profilePicture">
                                <img src="assets/imageholder.svg" *ngIf="!user?.profilePicture">
                            </ion-avatar>
                        </ion-col>
                    </ion-row>
                </ion-grid>
                <ion-label color="text-primary"><h4>{{user?.username}}</h4></ion-label><br>
                <ion-label color="text-primary">{{user?.fullname}}</ion-label><br>

                <ion-label color="text-color">
                    {{request?.comment}}
                </ion-label>


                <div *ngIf="payment?.caption">
                    <br>
                    <ion-label color="text-primary">
                        Tu comentario:
                    </ion-label>

                    <ion-label color="text-primary">
                        {{payment?.caption}}
                    </ion-label>
                    <br>
                </div><br>

                <ion-button fill="clear" color="text-primary">
                    <ion-icon slot="start" name="globe-outline" *ngIf="payment.privacy == 'public'"></ion-icon>
                    <ion-icon slot="start" name="lock" *ngIf="payment.privacy == 'private'"></ion-icon>
                    {{payment?.privacy}}
                </ion-button>


                <br>

                <ion-button color="primary" color="text-primary" fill="outline" size="small" (click)="paymentMethods($event)"
                    *ngIf="payment.payment_type == 'balance'">
                    <ion-label>
                        Saldo Pagï {{current_balance | currency:'ï '}}
                    </ion-label>
                    <ion-icon slot="end" name="caret-down-outline"></ion-icon>
                </ion-button>

                <ion-button color="primary" color="dark" fill="outline" size="small" (click)="paymentMethods($event)"
                    *ngIf="payment.payment_type !== 'balance'">
                    <ion-label *ngIf="card">
                        <ion-icon slot="start" src="./assets/icons/{{card.cardType}}.svg"></ion-icon>
                        • • • • {{card.cardMask}}
                    </ion-label>
                    <ion-label *ngIf="!card">
                        <p>
                            Selecciona un metodo de pago
                        </p>
                    </ion-label>
                    <ion-icon slot="end" name="caret-down-outline"></ion-icon>
                </ion-button>

                <br>
                <ion-text color="danger" *ngIf="cardError">
                    Debes de seleccionar un metodo de pago
                </ion-text>
                <br>
                <br>

                <ion-button color="primary" size="medium" (click)="sendPayment()">
                    <ion-icon slot="start" name="send-sharp"></ion-icon>
                    Enviar
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>


</ion-content>