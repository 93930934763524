<ion-header>
    <ion-toolbar color="fondo">
        <ion-buttons slot="start">
            <ion-button fill="clear" color="text-color" (click)="dismiss()">cancel</ion-button>
        </ion-buttons>
        <ion-title color="text-primary">Send pagï</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center">

    <form class="height-100" [formGroup]="paymentForm" novalidate>
        <ion-grid class="height-100">
            <ion-row class="height-100 ion-justify-content-center ion-align-items-center">
                <ion-col size="12">

                    <ion-text color="text-primary">
                        Enviar a
                    </ion-text>

                    <ion-grid>
                        <ion-row>
                            <ion-col size="4" offset="4">
                                <ion-avatar>
                                    <img src="{{friend?.profilePicture.downloadURL}}" *ngIf="friend?.profilePicture">
                                    <img src="assets/imageholder.svg" *ngIf="!friend?.profilePicture">
                                </ion-avatar>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <h4 class="saldo">{{friend?.username}}</h4>
                    <ion-label color="text-primary">{{friend?.fullname}}</ion-label><br>
                    <ion-label *ngIf="friend?.estado" color="text-color"> {{friend?.estado}}</ion-label>
                    <ion-label *ngIf="!friend?.estado" color="text-color"> Sin Estado</ion-label>
                    <br><br><br>

                    <ion-row class="margin-bottom centerItems">
                        <ion-col size="8" offset="2" class="number_pad ion-text-center ion-no-padding">
                            <ion-row>
                                <ion-col size="2">
                                    <ion-label color="text-primary" class="ion-text-right" style="font-size: 3em">
                                        ï
                                    </ion-label>
                                </ion-col>
                                <ion-col size="10" class="ion-text-left">
                                    <ion-input color="text-primary" class="input-number" mbsc-numpad-decimal [mbsc-options]="numpadSettings"
                                        formControlName="amount"></ion-input>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>

                    <ion-text class="justify-content-left" color="text-primary">Agrega una descripcion</ion-text>  
                        <ion-input style="border-bottom: #FFDB33; border-bottom-style: solid; " name="caption" placeholder="Add caption" class="ion-text-center" color="text-secondary"
                                    formControlName="caption">
                        </ion-input>

                    <br>
                    <ion-text class="justify-content-left" color="text-primary">Privacidad de la actividad</ion-text>  
 
                        <ion-select placeholder="Select One" interface="popover" formControlName="privacy">
                            <ion-select-option color="text-primary" value="public">
                                Public
                            </ion-select-option>
                            <ion-select-option color="text-primary" value="private">
                                Private
                            </ion-select-option>
                        </ion-select>
                        <br>

                    <ion-text class="justify-content-left" color="text-primary">Método de pago</ion-text><br>
                    <ion-button color="text-primary" color="text-primary" fill="outline" size="medium"
                        (click)="paymentMethods($event)">
                        <ion-label *ngIf="card">
                            <ion-icon slot="start" src="./assets/icons/{{card.cardType}}.svg"></ion-icon>
                            • • • • {{card.cardMask}}
                        </ion-label>
                        <ion-label *ngIf="!card">
                            <p>
                                Select a payment method
                            </p>
                        </ion-label>
                        <ion-icon slot="end" name="caret-down-outline"></ion-icon>
                    </ion-button>
                    <br>
                    <ion-text color="danger" *ngIf="cardError">
                        You must select a payment method
                    </ion-text>
                    <br>
                    <ion-button color="text-primary" (click)="sendPayment()" [disabled]="paymentForm.invalid">
                        Send payment
                    </ion-button>

                </ion-col>
            </ion-row>
        </ion-grid>
    </form>

</ion-content>