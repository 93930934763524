<ion-content class="border-color ion-padding" color="dark">
  <ion-row>
    <ion-col size="12">
      <ion-text color="text-primary" class="ion-text-center">
        <p>Confirma tu edad</p>
      </ion-text>

      <ion-text color="light">
        <p class="text-info">
          Esta información es confidencial y no se motrará en tu perfil.
        </p>
      </ion-text>
    </ion-col>
  </ion-row>

  <ion-text color="text-primary">
      <p class="no-margin">Fecha de nacimiento</p>
  </ion-text>
  <ion-input type="date" placeholder="DD/MM/YYYY" class="input-border"></ion-input>

  <ion-row class="ion-margin">
    <ion-col size="12" class="display-elements">
      <ion-button expand="block" fill="clear" class="btn-save">
        Guardar
      </ion-button>
    </ion-col>
  </ion-row>

</ion-content>
