import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddPaymentMethodComponent } from 'src/app/components/add-payment-method/add-payment-method.component';
import { CompleteInfoComponent } from 'src/app/components/complete-info/complete-info.component';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'app-payment-methods',
    templateUrl: './payment-methods.component.html',
    styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent implements OnInit {

    cards = [];
    account: any;
    card: false;

    constructor(
        public modalController: ModalController,
        public api: ApiService,
        private auth: AuthService,
    ) {
        this.api.getRef(`accounts/${this.auth.token}/cards`).ref
            .onSnapshot(snapshots => {
                snapshots.docChanges().forEach(change => {
                    if (change.type === "added") {
                        let card = change.doc.data();
                        card.$key = change.doc.id;
                        card.cardMask = card.cardNumber.substr(card.cardNumber.length - 4);
                        this.cards.push(card)
                    }
                });
            });

        this.api.getDocument(`accounts`, this.auth.token).then(account => {
            this.account = account;
        console.log(this.account);
        
        if ( !this.account.hasOwnProperty('dui') || !this.account.hasOwnProperty('phone')) {
            this.completaInfo();
        }
        

        })
    }

    ngOnInit() {

    }

    dismiss() {
        this.modalController.dismiss();
    }
    async addPaymentMethod() {
        const modal = await this.modalController.create({
            component: AddPaymentMethodComponent,
            componentProps: {
                'dataAccount': this.account,
            },
            cssClass: 'addPaymentModal',
            animated: true,
        });
        return await modal.present();
    }

    selectCard(card) {
        card.type = 'card';
        this.modalController.dismiss(card);
    }

    paggiBalance() {
        console.log("this.account.balance");
        console.log(this.account.balance);

        console.log();
        
        
        this.modalController.dismiss({ type: 'balance', 'balance': this.account.balance });
    }

    async completaInfo(){
        console.log("llamado de modal por falta de dui");
        const modal = await this.modalController.create({
            component: CompleteInfoComponent,
            backdropDismiss:false,
            cssClass: 'requestModal'
        });
        modal.onDidDismiss().then(data => {
            if (data.data == 'dismiss') {
                this.modalController.dismiss();
            }
        })
        return await modal.present();
        
        
    }
}
