import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, LoadingController, AlertController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { PaymentConfirmationComponent } from '../payment-confirmation/payment-confirmation.component';
import { SplitComponent } from '../split/split.component';
import { PaymentMethodsComponent } from '../../components/payment-methods/payment-methods.component';

@Component({
    selector: 'app-request-popup',
    templateUrl: './request-popup.component.html',
    styleUrls: ['./request-popup.component.scss'],
})
export class RequestPopupComponent implements OnInit {

    request: any;
    user: any;
    userToRequest: any;
    paymentForm: FormGroup;
    flagOrigin: any = '';
    visibleButton: boolean = false
    card: any;
    cardError: any = false;

    payment: any=[];
    current_balance: any;

    constructor(
        public modalCtrl: ModalController,
        public api: ApiService,
        public navParams: NavParams,
        private auth: AuthService,
        public route: ActivatedRoute,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public alertController: AlertController,
        public modalController: ModalController,
    ) {
        this.request = navParams.get('request');
        this.flagOrigin = navParams.get('flagOrigin');

        console.log("request-popup.components -> this.request",this.request, this.request.account_type)

        this.paymentForm = this.formBuilder.group({
            comment: [this.request.comment,[Validators.required]],
            amount: [this.request.amount,[Validators.required]],
            payment_type: ['balance'],
            privacy: ['public', [Validators.required]],
        });

        this.api.getRef('accounts').doc(this.request.from_account).ref
            .onSnapshot(snapshot => {
                let account = snapshot.data();
                account.$key = snapshot.id;
                this.user = account;
            });

        if (!this.request.shown) {
            this.api.updateDocument(`requests`, this.request.$key, { shown: true })
        }

        if (this.flagOrigin == 'request_canceled') {
            console.log("ingreso a validacion de fuente de informacion");
            this.visibleButton = true
            this.api.getRef('accounts').doc(this.request.to_account).ref
                .onSnapshot(snapshot => {
                    let account = snapshot.data();
                    account.$key = snapshot.id;
                    this.userToRequest = account;
                    // this.user = account
                    console.log(this.user);
                    
                });
        }

    }

    ngOnInit() { }

    async paymentMethods(event: any) {
        const modal = await this.modalController.create({
            component: PaymentMethodsComponent,
        });
    
        modal.onDidDismiss().then(data => {
            this.card = data.data;
    
            if (this.card == undefined) {
                this.card = false;
                this.cardError = true;
            } else {
                this.cardError = false;
            }
    
        })
        console.log(this.card);
        console.log(this.cardError);
        return await modal.present();
    }

    close_btn() {
        this.modalCtrl.dismiss();
    }

     async sendPayment() {
        
        
         const modal = await this.modalController.create({
             component: PaymentConfirmationComponent,
             componentProps: {
                 'request': this.request,
                 'user': this.user,
                 'payment': this.paymentForm.value
             }
         });

         modal.onDidDismiss().then(data => {
             if (data.data == 'dismiss') {
                 this.modalController.dismiss();
             }
         })
         return await modal.present();
     }

    async cancelRequest() {
        const alert = await this.alertController.create({
            message: `Esta seguro que deseas eliminar la Solicitud de ${this.user.username} ?`,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirmando Cancel');
                    }
                }, {
                    text: 'Si, estoy seguro',
                    handler: () => {
                        this.api.updateDocument('requests', this.request.$key, { status: 'refuse' }).then(data => {
                            this.modalController.dismiss();
                        })

                    }
                }
            ]
        });

        await alert.present();
    }

    async cancelPayment() {
        console.log("cancelPayment");
        console.log(this.user);
        console.log();

        const alert = await this.alertController.create({
            message: `Esta seguro que deseas eliminar la Solicitud realizada hacia ${this.userToRequest.username} ?`,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirmando Cancel');
                    }
                }, {
                    text: 'Si, estoy seguro',
                    handler: () => {
                        this.api.updateDocument('requests', this.request.$key, { status: 'anulado' }).then(data => {
                            this.modalController.dismiss();
                        })

                    }
                }
            ]
        });

        await alert.present();
    }

    async splitAccount() {

        const modal = await this.modalController.create({
            component: SplitComponent,
            componentProps: {
                'request': this.request,
                'user': this.user,
                'payment': this.paymentForm.value
            }
        });

        modal.onDidDismiss().then(data => {
            if (data.data == 'dismiss') {
                this.modalController.dismiss();
            }
        })
        return await modal.present();
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            header: '¡Felicidades!',
            message: '¡tu pago fue enviado correctamente!',
            cssClass: 'successAlert',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Okay',
                    cssClass: 'okBtn',
                    handler: () => {
                        this.modalController.dismiss('dismiss');
                    }
                }
            ]
        });

        await alert.present();
    }
    
    //Enviar Pago
    sendPayment1() {
        if (this.paymentForm.controls['payment_type'].value == 'balance') {
            this.loader().then(loader => {
                loader.present().then(() => {
                    this.payment.amount = this.request.amount;

                    this.api.getDocument('accounts', this.auth.token).then(account_result => {
                        let balance = account_result['balance'];
                        console.log(balance);
                        
                        if (balance >= this.payment.amount) {
                            this.payment.from_balance = balance - this.payment.amount;

                            this.api.sendPayment(this.payment, this.request, this.user.$key).then(data => {
                                loader.dismiss();
                                this.presentAlert();
                            });

                        } else {
                            loader.dismiss();
                            this.alertController.create({
                                header: '¡Ups!',
                                message: '¡No cuentas con el suficiente saldo pagï para realizar este pago!',
                                cssClass: 'successAlert',
                                backdropDismiss: false,
                                buttons: [
                                    {
                                        text: 'Okay',
                                        cssClass: 'okBtn',
                                        handler: () => {
                                            this.modalController.dismiss('dismiss');
                                        }
                                    }
                                ]
                            }).then(alert => {
                                alert.present();
                            })
                        }
                    })
                })
            });

        } else {
            if (this.card) {
                this.cardError = false;
                this.loader().then(loader => {
                    loader.present().then(() => {
                        this.payment.card = this.card;
                        this.payment.amount = this.request.amount;

                        this.api.sendPayment(this.payment, this.request, this.user.$key).then(data => {
                            loader.dismiss();
                            this.presentAlert();
                        });

                    })
                });

            } else {
                this.cardError = true;
            }
        }

    }


}
