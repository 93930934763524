<!-- <ion-content class="ion-padding" color="light">
    <ion-buttons>
        <ion-button shape="round" expand="block" class="close-btn" (click)="dismiss()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
    </ion-buttons>


    <form [formGroup]="cardForm" novalidate>

        <ion-row>
            <ion-col>
                <ion-text color="dark">
                    Card holder name
                </ion-text>
                <ion-item lines="none">
                    <ion-input type="text" color="dark" formControlName="cardName"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <ion-text color="dark">
                    Card Number
                </ion-text>
                <ion-item lines="none">
                    <input type="tel" formControlName="cardNumber" />
                    <ion-icon name="card-outline" color="dark" slot="start" *ngIf="type == 'unknown'"></ion-icon>
                    <ion-icon src="./assets/icons/{{type}}.svg" color="dark" slot="start" *ngIf="type !== 'unknown'">
                    </ion-icon>

                </ion-item>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col size="6">
                <ion-text color="dark">
                    Exp. Date
                </ion-text>
                <ion-item lines="none">
                    <ion-input type="tel" formControlName="cardExpDate"></ion-input>
                </ion-item>

            </ion-col>
            <ion-col size="6">
                <ion-text color="dark">
                    CVV
                </ion-text>
                <ion-item lines="none">
                    <ion-input type="tel" formControlName="cardCvv"></ion-input>
                </ion-item>

            </ion-col>
        </ion-row>

        <div class="ion-text-center">
            <ion-button color="dark" (click)="addCard()" [disabled]="cardForm.invalid">
                Agregar tarjeta
            </ion-button>
        </div>
    </form> 

</ion-content>-->

<ion-content class="ion-padding" color="fondo">
    <ion-buttons>
        <ion-button shape="round" expand="block" class="close-btn" (click)="dismiss()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
    </ion-buttons>
    <form [formGroup]="cardForm" novalidate>

        <ion-row>
            <ion-col>
                <ion-text color="text-primary">
                    Nombre del titular
                </ion-text>
                <ion-item lines="none" color="fondo">
                    <ion-input type="text" class="ion-text-capitalize" formControlName="cardName"></ion-input>
                </ion-item>
                    <ion-text  *ngIf="cardForm.get('cardName').hasError('required')" color="text-tertiary">Campo es requerido</ion-text>
                    <ion-text color="text-tertiary" *ngIf="cardForm.get('cardName').hasError('pattern')" >Solo carácteres alfabéticos.</ion-text>
                </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <ion-text color="text-primary">
                    Número de tarjeta
                </ion-text>
                <ion-item lines="none" color="fondo">

                    <ion-icon color="light" name="card" slot="start" *ngIf="type == 'unknown'"></ion-icon>

                    <ion-icon src="./assets/icons/{{type}}.svg" slot="start" *ngIf="type !== 'unknown'">
                    </ion-icon>
                    <ion-input type="tel" color="medium" formControlName="cardNumber"  mask="0000 0000 0000 0000">
                    </ion-input>
                </ion-item>
                    <ion-text  *ngIf="cardForm.get('cardNumber').hasError('required')" color="text-tertiary">Campo es requerido</ion-text>
                </ion-col>
        </ion-row>

        <ion-row>
            <ion-col size="6">
                <ion-text color="text-primary">
                    Fecha de expe.
                </ion-text>
                <ion-item lines="none" color="fondo">
                    <ion-input type="tel" placeholder="MM/YY" formControlName="cardExpDate" id="cardExpDate" (keydown)="soloNumeros($event)" mask="00/00"></ion-input>
                </ion-item>
                <ion-text  *ngIf="cardForm.get('cardExpDate').hasError('required')" color="text-tertiary">Campo es requerido</ion-text>
                <ion-text  *ngIf="cardForm.get('cardExpDate').hasError('pattern')" color="text-tertiary">Fecha incorrecta</ion-text>
            </ion-col>
            <ion-col size="6">
                <ion-text color="text-primary">
                    CVV
                </ion-text>
                <ion-item lines="none" color="fondo">
                    <ion-input color="medium" type="tel" formControlName="cardCvv" mask="0000"></ion-input>
                </ion-item>
                <ion-text *ngIf="cardForm.get('cardCvv').hasError('required')" color="text-tertiary">Campo es requerido</ion-text>
                <ion-text color="danger">{{mensajeCvv}}</ion-text>

            </ion-col>
        </ion-row>

        <div class="ion-text-center">
            <ion-button color="fondo" (click)="addCard()" [disabled]="cardForm.invalid">
                Agregar tarjeta
            </ion-button>
        </div>
    </form>

</ion-content>