<ion-header collapse="condense">
    <ion-toolbar color="dark">
        <form action=".">
            <ion-searchbar debounce="300" (ionChange)="search($event)" animated cancelButtonText="Cancel">
            </ion-searchbar>
        </form>
    </ion-toolbar>
</ion-header>

<ion-content [scrollEvents]="true" (ionScroll)="onScroll()" (ionScrollStart)="onScroll()" class="ion-no-padding">

    <ion-grid *ngIf="loading">
        <ion-row class="ion-justify-content-center ion-align-items-center ion-padding ion-margin">
            <ion-spinner name="dots"></ion-spinner>
        </ion-row>
    </ion-grid>

    <div *ngIf="!loading">
        <ion-list *ngIf="searching">

            <ion-item *ngFor="let user of accountsResult" lines="none" detail="false" (click)="addToRecent(user.$key)">
                <ion-avatar slot="start">
                    <img src="{{user.profilePicture.downloadURL}}" *ngIf="user.profilePicture">
                    <img src="assets/imageholder.svg" *ngIf="!user.profilePicture">
                </ion-avatar>
                <ion-label>
                    <h3>{{user.username}}</h3>
                    <p>{{user.fullname}}</p>
                </ion-label>
                <ion-checkbox slot="end" [checked]="user.checked" (ionChange)="userToSplit($event, user)">
                </ion-checkbox>
            </ion-item>
        </ion-list>

        <ion-list *ngIf="!searching">
            <ion-item *ngFor="let user of recents" lines="none" detail="false">
                <ion-avatar slot="start">
                    <img src="{{user.profilePicture.downloadURL}}" *ngIf="user.profilePicture">
                    <img src="assets/imageholder.svg" *ngIf="!user.profilePicture">
                </ion-avatar>
                <ion-label>
                    <h3>{{user.username}}</h3>
                    <p>{{user.fullname}}</p>
                </ion-label>
                <ion-checkbox slot="end" [checked]="user.checked" (ionChange)="userToSplit($event, user)">
                </ion-checkbox>
            </ion-item>
        </ion-list>
    </div>
</ion-content>

<ion-footer>
    <ion-toolbar color="dark">
        <ion-button size="full" color="dark" (click)="continue()">Continue</ion-button>
    </ion-toolbar>
</ion-footer>